import {
  BackendResponse,
  Company,
  User,
} from '../app/types';
import { Brand } from '../brands/types';

export const types = Object.freeze({
  USERS_FETCH: '[CMSUsers] USERS_FETCH',
  USER_POST: '[CMSUsers] USER_POST',
  USER_PATCH: '[CMSUsers] USER_PATCH',
  USER_DELETE: '[CMSUsers] USER_DELETE',
  COMPANIES_FETCH: '[CMSUsers] COMPANIES_FETCH',
  COMPANY_POST: '[CMSUsers] COMPANY POST',
  COMPANY_PATCH: '[CMSUsers] COMPANY PATCH',
  COMPANY_DELETE: '[CMSUsers] COMPANY DELETE',
});

export type CMSUsersState = {
  usersProperties: GetUsers | null;
  companiesProperties: GetCompanies | null;
};

export type GetUsersResponse = BackendResponse<GetUsers>;
export type PostUserResponse = BackendResponse<PostUser>;
export type PatchUserResponse = BackendResponse<PatchUser>;
export type DeleteUserResponse = BackendResponse<DeleteUser>;

export type PostUser = {
  createdUserId: number;
};

export type PatchUser = {
  updatedUserId: number;
};

export type DeleteUser = {
  deletedUserId: number;
};

// Users tab types
export type GetUsers = {
  users: User[];
  roles: Role[];
  companies: Company[];
};

export type PatchUpdateUser = Pick<User, 'userId'> & Partial<Pick<User, 'userStatus' | 'companyId' | 'roleId'>>;

export type PatchUpdateUserRequest = PatchUpdateUser;

export type PostAddUser = Pick<User, 'userEmail' | 'companyId' | 'roleId'>;

export type PostAddUserRequest = PostAddUser;

export type Role = {
  roleId: number;
  roleName: string;
};

// Companies tab types
export type GetCompaniesResponse = BackendResponse<GetCompanies>;
export type PostCompanyResponse = BackendResponse<PostCompany>;
export type PatchCompanyResponse = BackendResponse<PatchCompany>;
export type DeleteCompanyResponse = BackendResponse<DeleteCompany>;

export type CompanyBrands = Company & {
  brands: Brand[];
};

export type GetCompanies = CompanyBrands[];
export type PostCompany = {
  createdCompanyId: number;
};

export type PatchCompany = {
  updatedCompanyId: number;
};

export type DeleteCompany = {
  deletedCompanyId: number;
};

export type PostAddCompany = Pick<Company, 'companyName' | 'companyType'>;

export type PostAddCompanyRequest = PostAddCompany;

export type PatchUpdateCompany = Pick<Company, 'companyId'> & {
  companyType: string;
};

export type PatchUpdateCompanyRequest = PatchUpdateCompany;
