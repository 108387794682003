import {
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import {
  CasinoGamesState,
  CompanyGameForEditor,
  GetGames,
  GetGameSettings,
  GetNewGames,
  GetGamesCategories,
  GetCategoryGroups,
  GetCategoryGroup,
} from './types';
import {
  fetchGames,
  fetchGameData,
  fetchGameSettings,
  fetchNewGames,
  fetchGamesCategories,
  fetchCategoryGroups,
  fetchCategoryGroup,
} from './actions';

const initialState: CasinoGamesState = {
  filterState: null,
  gamesProperties: null,
  gameEditor: null,
  pamInstancesCollection: null,
  gameSettings: null,
  brandsRegulationsCountries: null,
  newGamesProperties: null,
  gamesCategoriesProperties: null,
  categoryGroups: null,
  currentCategoryGroups: null,
};

export const casinoGamesSlice = createSlice({
  name: 'casinoGames',
  initialState,
  reducers: {
    clearGamesState(state) {
      state.gamesProperties = null;
    },
    clearGameDataState(state) {
      state.gameEditor = null;
    },
    clearGameSettingsState(state) {
      state.gameSettings = null;
    },
    clearNewGamesState(state) {
      state.newGamesProperties = null;
    },
    clearGamesCategoriesState(state) {
      state.gamesCategoriesProperties = null;
    },
    clearCurrentCategoryGroups(state) {
      state.currentCategoryGroups = null;
    },
    setFilterState(state) {
      if (state.gamesProperties) {
        state.filterState = state.gamesProperties.filterSettings;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchGames.fulfilled, (
      state: CasinoGamesState,
      action: PayloadAction<GetGames | null>,
    ) => {
      if (action.payload !== null) {
        state.gamesProperties = action.payload;
      }
    });

    builder.addCase(fetchGameData.fulfilled, (
      state: CasinoGamesState,
      action: PayloadAction<CompanyGameForEditor | null>,
    ) => {
      if (action.payload !== null) {
        state.gameEditor = action.payload;
      }
    });

    builder.addCase(fetchNewGames.fulfilled, (
      state: CasinoGamesState,
      action: PayloadAction<GetNewGames | null>,
    ) => {
      if (action.payload !== null) {
        state.newGamesProperties = action.payload;
      }
    });

    builder.addCase(fetchGameSettings.fulfilled, (
      state: CasinoGamesState,
      action: PayloadAction<GetGameSettings | null>,
    ) => {
      if (action.payload !== null) {
        state.gameSettings = action.payload.settingsByBrand;
        state.pamInstancesCollection = action.payload.pamInstancesCollection;
        state.brandsRegulationsCountries = action.payload.brandsRegulationsCountries;
      }
    });

    builder.addCase(fetchGamesCategories.fulfilled, (
      state: CasinoGamesState,
      action: PayloadAction<GetGamesCategories | null>,
    ) => {
      if (action.payload !== null) {
        state.gamesCategoriesProperties = action.payload;
      }
    });

    builder.addCase(fetchCategoryGroups.fulfilled, (
      state: CasinoGamesState,
      action: PayloadAction<GetCategoryGroups | null>,
    ) => {
      if (action.payload !== null) {
        state.categoryGroups = action.payload;
      }
    });

    builder.addCase(fetchCategoryGroup.fulfilled, (
      state: CasinoGamesState,
      action: PayloadAction<GetCategoryGroup | null>,
    ) => {
      if (action.payload !== null) {
        state.currentCategoryGroups = action.payload;
      }
    });
  },
});
