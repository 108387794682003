type ExpectedOrderType = { [key: number]: number };

export const UtilsService = {
  isSeparatorAvailable<T>(index: number, items: Array<T>): boolean {
    return index < items?.length - 1;
  },

  divideArrayOnHalf<T>(array: Array<T>): Array<T>[] {
    const halfList: number = Math.ceil(array?.length / 2);

    return [array?.slice(0, halfList), array?.slice(halfList)];
  },

  getFileNameWithoutExtension(fileName: string): { name: string; extension: string } {
    const lastDot = fileName?.lastIndexOf('.');
    const name = fileName?.slice(0, lastDot);
    const extension = fileName?.slice(lastDot + 1);

    return {
      name,
      extension,
    };
  },

  getUniqueListByKey<T>(list: Array<T>, key: keyof T) {
    const mapFromList = new Map(
      list?.map(listItem => [listItem[key], listItem]),
    );
    return [...mapFromList.values()];
  },

  getEnumNumberValues(enumObj: object): number[] {
    return Object.values(enumObj).filter(v => !isNaN(Number(v)));
  },

  toCamelCase(str: string): string {
    let result = '';
    let isNewWord = false;

    for (let i = 0; i < str.length; i++) {
      const char = str[i];

      if (char === ' ' || char === '-' || char === '_') {
        isNewWord = true;
        continue;
      }

      if (i === 0) {
        result += char.toLowerCase();
      } else if (isNewWord) {
        result += char.toUpperCase();
        isNewWord = false;
      } else {
        result += char.toLowerCase();
      }
    }

    return result;
  },

  async downloadFile(fileUrl: string, fileName: string) {
    try {
      // Fetch the image from the given URL
      const response = await fetch(fileUrl);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const blob = await response.blob();

      // Create a temporary URL for the blob
      const url = window.URL.createObjectURL(blob);

      // Create an anchor tag and trigger the download
      const link = document.createElement('a');
      link.href = url;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();

      // Clean up and revoke the object URL
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading the image:', error);
    }
  },

  sortByProperty<T>(array: T[], property: keyof T, expectedOrder: ExpectedOrderType): T[] {
    return [...array].sort((a, b) => {
      if (a[property] && b[property]) {
        return (expectedOrder[a[property] as keyof ExpectedOrderType]) -
          (expectedOrder[b[property] as keyof ExpectedOrderType]);
      } else if (a[property]) {
        return -1;
      } else if (b[property]) {
        return 1;
      } else {
        return 0;
      }
    });
  },
};
