import React, {
  ChangeEvent,
  FC,
} from 'react';
import './PureInput.scss';
import classNames from 'classnames';
import { PureInputTypesEnum } from './types';

interface Props {
  value: string | number;
  type: PureInputTypesEnum;
  className?: string;
  placeholder?: string;
  handleChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  autoFocus?: boolean;
}

export const PureInput: FC<Props> = ({
  value,
  type,
  className,
  placeholder,
  disabled = false,
  handleChange = () => {
    return;
  },
  autoFocus = false,
}) => {
  const blockSymbolsIfNumberType = (e: { key: string; preventDefault: () => void }) =>
    type === PureInputTypesEnum.NUMBER ? ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault() : null;

  return (
    <input
      type={type}
      value={value}
      placeholder={placeholder}
      className={classNames('pure-input', className)}
      onChange={handleChange}
      disabled={disabled}
      onKeyDown={blockSymbolsIfNumberType}
      // eslint-disable-next-line jsx-a11y/no-autofocus
      autoFocus={autoFocus}
    />
  );
};
