import { FC } from 'react';
import './NoInternetNotification.scss';
import { Icon } from '../Icon/Icon';
import { IconEnums } from 'common/IconEnums';
import { SystemCta } from '../SystemCta/SystemCta';

export const NoInternetNotification: FC = () => {
  const reloadPage = () => window.location.reload();

  return (
    <div className="no-internet-notification">
      <Icon iconName={IconEnums.NO_INTERNET} />

      <div>No internet connection</div>

      <SystemCta text="Refresh" onClick={reloadPage} className="refresh-button" />
    </div>
  );
};
