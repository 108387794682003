import { createAsyncThunk } from '@reduxjs/toolkit';
import { endpoints } from 'config/endpoints';
import {
  GetLocalizationPropertiesFields,
  GetLocalizationPropertiesResponse,
  GetNetworkExperienceFields,
  GetNetworkExperienceResponse,
  GetPamInstancesFields,
  GetPamInstancesResponse,
  PatchLocalizationPropertiesRequest,
  PatchLocalizationPropertiesResponse,
  PutNetworkExperienceRequest,
  PutNetworkExperienceResponse,
  PutPamInstancesRequest,
  PutPamInstancesResponse,
  types,
  UpdatedExperienceItems,
} from './types';
import { AsyncThunkParams } from '../app/types';
import { BackendResponseService } from '../../services/BackendResponseService';

export const fetchLocalizationProperties = createAsyncThunk<
GetLocalizationPropertiesFields | null,
AsyncThunkParams<GetLocalizationPropertiesResponse>
>(
  types.LOCALIZATION_PROPS_FETCH,
  async (
    props,
  ) => {
    try {
      const result = await props.execute('GET', endpoints.localizationProperties);
      return BackendResponseService.getBackendResponseData(result);
    } catch (err) {
      console.error(types.LOCALIZATION_PROPS_FETCH, err);
      return null;
    }
  },
);

export const patchLocalizationProperties = createAsyncThunk<
void | null,
AsyncThunkParams<PatchLocalizationPropertiesResponse, PatchLocalizationPropertiesRequest>
>(
  types.LOCALIZATION_PROPS_PATCH,
  async (
    { execute, payload },
  ) => {
    try {
      const result = await execute(
        'PATCH',
        endpoints.localizationProperties,
        payload,
      );
      return BackendResponseService.getBackendResponseData(result);
    } catch (err) {
      console.error(types.LOCALIZATION_PROPS_PATCH, err);
      throw err;
    }
  },
);

export const fetchPamInstances = createAsyncThunk<
GetPamInstancesFields | null,
AsyncThunkParams<GetPamInstancesResponse>
>(
  types.CREDENTIALS_PAM_INSTANCES_FETCH,
  async (
    props,
  ) => {
    try {
      const result = await props.execute('GET', endpoints.credentialsPamInstances);
      return BackendResponseService.getBackendResponseData(result);
    } catch (err) {
      console.error(types.CREDENTIALS_PAM_INSTANCES_FETCH, err);
      return null;
    }
  },
);

export const putPamInstances = createAsyncThunk<
void | null,
AsyncThunkParams<PutPamInstancesResponse, PutPamInstancesRequest>
>(
  types.CREDENTIALS_PAM_INSTANCES_PUT,
  async (
    { execute, payload },
  ) => {
    try {
      const result = await execute(
        'PUT',
        endpoints.credentialsPamInstances,
        payload,
      );
      return BackendResponseService.getBackendResponseData(result);
    } catch (err) {
      console.error(types.CREDENTIALS_PAM_INSTANCES_PUT, err);
      throw err;
    }
  },
);

export const fetchNetworkExperience = createAsyncThunk<
GetNetworkExperienceFields | null,
AsyncThunkParams<GetNetworkExperienceResponse>
>(
  types.NETWORK_EXPERIENCE_FETCH,
  async (
    props,
  ) => {
    try {
      const result = await props.execute('GET', endpoints.networkExperience);
      return BackendResponseService.getBackendResponseData(result);
    } catch (err) {
      console.error(types.NETWORK_EXPERIENCE_FETCH, err);
      return null;
    }
  },
);

export const putNetworkExperience = createAsyncThunk<
UpdatedExperienceItems | null,
AsyncThunkParams<PutNetworkExperienceResponse, PutNetworkExperienceRequest>
>(
  types.NETWORK_EXPERIENCE_PUT,
  async (
    { execute, payload },
  ) => {
    try {
      const result = await execute(
        'PUT',
        endpoints.networkExperiencePut,
        payload,
      );
      return BackendResponseService.getBackendResponseData(result);
    } catch (err) {
      console.error(types.NETWORK_EXPERIENCE_PUT, err);
      throw err;
    }
  },
);
