import { FC } from 'react';
import './Notification.scss';
import { NotificationEnum } from './types';
import classNames from 'classnames';
import { Icon } from '../Icon/Icon';
import { IconEnums } from 'common/IconEnums';
import { useTimeout } from 'hooks/use-timeout';

interface Props {
  message: string;
  type: NotificationEnum;
  onClick: () => void;
  delay?: number;
}

export const Notification: FC<Props> = ({
  message,
  type,
  onClick,
  delay = 4000,
}) => {
  useTimeout(onClick, delay);

  const notificationClassName = classNames({
    'notification': true,
    [`notification--${type}`]: type,
  });

  const iconType = {
    [NotificationEnum.SUCCESS]: IconEnums.MARK,
    [NotificationEnum.INFO]: IconEnums.NOTIFICATION_ATTENTION,
    [NotificationEnum.ERROR]: IconEnums.NOTIFICATION_ATTENTION,
  };

  return (
    <div className="notification-container">
      <div className={notificationClassName} onClick={onClick} role="presentation">
        <Icon iconName={iconType[type]} bgColor={type === NotificationEnum.ERROR ? 'white' : ''} />
        {message}
      </div>
    </div>
  );
};
