import { useAppSelector } from 'store/hooks';
import {
  useEffect,
  useState,
} from 'react';
import {
  CollectionByKey,
  Language,
  Regulation,
} from 'store/app/types';
import { SelectItem } from 'components/shared/Select/Select';

export const useBrandSettings = () => {
  const [regulationsById, setRegulationsById] = useState<null | CollectionByKey<Regulation>>(null);
  const [languagesById, setLanguagesById] = useState<null | CollectionByKey<Language>>(null);
  const { regulations, languages } = useAppSelector((state) => state.app);
  const { brandSettings } = useAppSelector((state) => state.brands);
  const [companiesSelectList, setCompaniesSelectList] = useState<SelectItem[]>([]);
  const [
    pamInstancesSelectList,
    setPamInstancesSelectList,
  ] = useState<SelectItem[] | null>(null);

  useEffect(() => {
    if (regulations !== null) {
      setRegulationsById(regulations.reduce<CollectionByKey<Regulation>>((acc, regulation) => {
        acc[regulation.regulationId] = regulation;

        return acc;
      }, {}));
    }
  }, [regulations]);

  useEffect(() => {
    if (languages !== null) {
      setLanguagesById(languages.reduce<CollectionByKey<Language>>((acc, language) => {
        acc[language.languageId] = language;

        return acc;
      }, {}));
    }
  }, [languages]);

  useEffect(() => {
    if (brandSettings !== null) {
      setCompaniesSelectList(brandSettings.companies.map((company) => ({
        value: company.companyId,
        text: company.companyName,
      })));
      setPamInstancesSelectList(brandSettings.allPamInstances.map((pamInstance) => ({
        value: pamInstance.pamInstanceId,
        text: pamInstance.pamInstanceDescription,
      })));
    }
  }, [brandSettings]);

  return  {
    brandSettings,
    regulationsById,
    languagesById,
    companiesSelectList,
    pamInstancesSelectList,
  };
};
