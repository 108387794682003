import { createContext } from 'react';
import { NotificationContextActions } from 'components/shared/NotificationProvider/types';

const defaultValue = {
  open: () => {
    return;
  },
  noInternet: () => {
    return;
  },
  majorLoading: () => {
    return;
  },
  systemNotification: () => {
    return;
  },
  closeSystemNotification: () => {
    return;
  },
};

export const NotificationContext = createContext<NotificationContextActions>(defaultValue);
