import {
  configureStore,
  ThunkAction,
  Action,
} from '@reduxjs/toolkit';
import { appSlice } from './app/reducer';
import { networkEditorSlice } from './network-editor/reducer';
import { cmsUsersSlice } from './cms-users/reducer';
import { regulationsSlice } from './regulations/reducer';
import { brandsSlice } from './brands/reducer';
import { assetsSlice } from './assets/reducer';
import { casinoGamesSlice } from './casino-games/reducer';
import { menusSlice } from './menus/reducer';
import { networkMediaSlice } from './network-media/reducer';
import * as appThunks from './app/actions';
import * as networkEditorThunks from './network-editor/actions';
import * as cmsUsersThunks from './cms-users/actions';
import * as brandsThunks from './brands/actions';
import * as regulationsThunks from './regulations/actions';
import * as assetsThunk from './assets/actions';
import * as casinoGamesThunk from './casino-games/actions';
import * as menusThunk from './menus/actions';
import * as experienceThunk from './experience/actions';
import { experienceSlice } from './experience/reducer';
import * as networkMediaThunk from './network-media/actions';

export const store = configureStore({
  reducer: {
    app: appSlice.reducer,
    networkEditor: networkEditorSlice.reducer,
    cmsUsers: cmsUsersSlice.reducer,
    brands: brandsSlice.reducer,
    regulations: regulationsSlice.reducer,
    assets: assetsSlice.reducer,
    casinoGames: casinoGamesSlice.reducer,
    menus: menusSlice.reducer,
    experience: experienceSlice.reducer,
    networkMedia: networkMediaSlice.reducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
ReturnType,
RootState,
unknown,
Action<string>
>;
export const allActions = {
  ...appSlice.actions,
  ...appThunks,
  ...networkEditorSlice.actions,
  ...networkEditorThunks,
  ...cmsUsersSlice.actions,
  ...cmsUsersThunks,
  ...brandsSlice.actions,
  ...brandsThunks,
  ...regulationsSlice.actions,
  ...regulationsThunks,
  ...assetsSlice.actions,
  ...assetsThunk,
  ...casinoGamesSlice.actions,
  ...casinoGamesThunk,
  ...menusSlice.actions,
  ...menusThunk,
  ...experienceThunk,
  ...experienceSlice.actions,
  ...networkMediaSlice.actions,
  ...networkMediaThunk,
};
