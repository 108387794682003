import {
  BackendResponse,
  Company,
} from '../app/types';
import { License } from '../regulations/types';
import { PamInstance } from '../network-editor/types';
import { BrandAsset } from '../assets/types';
import { SegmentEnum } from '../../common/SegmentEnum';

export const types = Object.freeze({
  BRANDS_FETCH: '[Brands] BRANDS_FETCH',
  BRAND_PATCH: '[Brands] BRAND PATCH',
  BRAND_DELETE: '[Brands] BRAND_DELETE',
  BRANDS_SETTINGS_FETCH: '[Brands] BRAND BRANDS_SETTINGS_FETCH',
  NEW_BRAND_POST: '[Brands] BRAND NEW_BRAND_POST',
  BRAND_PUT_GENERALS: '[Brands] BRAND BRAND_PUT_GENERALS',
  BRANDS_SETTINGS_DETAILS_PUT: '[Brands] BRAND BRANDS_SETTINGS_DETAILS_PUT',
  BRANDS_SETTINGS_AUTHORIZATION_PUT: '[Brands] BRAND BRANDS_SETTINGS_AUTHORIZATION_PUT',
  BRANDS_SETTINGS_INTEGRATIONS_PUT: '[Brands] BRAND BRANDS_SETTINGS_INTEGRATIONS_PUT',
});

export enum BrandTypesEnum {
  CUSTOMER_BRAND = 1,
  SANDBOX = 2,
  ATOMIC = 3,
}

export enum BrandTemplatesEnum {
  T1 = 1,
  API = 2,
  M = 3,
}

export type BrandsState = {
  brands: GetBrands | null;
  brandSettings: BrandSettings | null;
};

export enum BrandStatusesEnum {
  LIVE = 1,
  DRAFT = 2,
  FROZEN = 3,
}

export type GetBrandsResponse = BackendResponse<GetBrands>;
export type PatchBrandResponse = BackendResponse<PatchBrand>;
export type GetBrandSettingsResponse = BackendResponse<BrandSettings>;
export type PostNewBrandResponse = BackendResponse<Brand>;
export type PutBrandGeneralsResponse = BackendResponse<void>;
export type PutBrandSettingsDetailsResponse = BackendResponse<void>;
export type PutBrandSettingsAuthorizationResponse = BackendResponse<void>;
export type DeleteBrandResponse = BackendResponse<DeleteBrand>;

export type GetBrands = Brand[];

export type Brand = {
  siteId: number;
  casinoId: number | null;
  brandName: string;
  companyId: number | null;
  companyName: string;
  companyType: number;
  brandTemplate: BrandTemplatesEnum;
  brandType: BrandTypesEnum;
  brandStatus: number;
  androidEnabled: boolean;
  iosEnabled: boolean;
  assets: {
    favicon: {
      small: BrandAsset;
      big: BrandAsset;
    };
  };
};

export type PatchBrand = {
  updatedBrandId: number;
};

export type DeleteBrand = {
  deletedSiteId: number;
};

export type PatchUpdateBrand = Pick<Brand, 'companyId'> & { brandIds: number[] };

export type PatchUpdateBrandRequest = Pick<Brand, 'companyId' | 'siteId'>;

export type PutBrandSettingDetailsRequest = Pick<Brand, 'siteId'> & BrandSettingsDetails;
export type PutBrandSettingIntegrationsResponse = BackendResponse<void>;

export type BrandSettings = {
  general: BrandSettingsGeneral;
  details: BrandSettingsDetails;
  authorization: BrandSettingsAuthorization;
  integrations: BrandSettingsIntegrations;
  companies: Company[];
  ngLicenses: License[];
  allPamInstances: PamInstance[];
};

export type BrandSettingsGeneral = {
  casinoId: number | null;
  brandStatus: BrandStatusesEnum;
  brandTemplate: BrandTemplatesEnum;
  brandType: BrandTypesEnum;
  androidEnabled: boolean;
  iosEnabled: boolean;
  redirectToLink: string | null;
};

export type BrandSettingsDetails = {
  brandName: string;
  companyId: number | null;
  regulationIds: number[];
  languageIds: number[];
  brandDomains: BrandDomains;
  brandRegulationsLanguages: BrandLanguage[];
  brandLicenses: BrandLicense[];
};

export type BrandDomains = {
  primary: BrandDomain;
  secondary: BrandDomain[];
};

export type BrandDomain = {
  domain: string;
  regulations: BrandEntityLink;
};

export type BrandLanguage = {
  regulationId: number;
  languages: BrandEntityLink;
};

export type BrandEntityLink = {
  primary: number;
  secondary: number[];
};

export type BrandLicense = {
  regulationId: number;
} & (
  {
    licenseType: LicenseTypeEnum.NG;
    licenseId: number;
  } | {
    licenseType: LicenseTypeEnum.BRAND;
    companyName: string;
    address: string;
    licenseNumber: string;
  }
);

export enum LicenseTypeEnum {
  NG = 1,
  BRAND = 2,
}

export type BrandSettingsAuthorization = {
  brandVerticals: BrandVertical[];
  brandSegmentsFeatures: BrandSegmentFeature[];
  featuresGroups: FeatureGroup[];
  verticals: Vertical[];
};

export type Vertical = {
  verticalId: number;
  verticalName: string;
};

export type BrandVertical = Vertical & {
  siteId: number;
};

export type FeatureGroup = Feature & {
  featureGroupId: number;
  featureGroupName: string;
  isHidden: boolean;
};

export type Feature = {
  featureId: number;
  featureName: string;
  featureGroupId?: number;
};

export type GroupedFeatures = {
  [featureGroupId: number]: {
    featureGroupName: string;
    features: Feature[];
  };
};

export type FeatureSegment = {
  featureId: number;
  segmentId: number;
};

export type BrandSegmentFeature = {
  siteId: number;
  segmentId: number;
  segmentName: string;
  featureId: number;
  featureName: string;
};

export type PamInstanceConnection = {
  regulationId: number;
  pamInstanceId: number;
};

export type BrandSettingsIntegrations = {
  googleAnalytics: {
    gId: string;
  };
  chat: {
    chatIntegrationType: number;
    fcKey: string;
    fcUrl: string;
    fcLoginStatus: number;
    fcFaq: boolean;
    fcAutoOpen: boolean;
  };
  tickets: {
    ticketsIntegrationType: number;
    key: string;
  };
  dataPlatform: {
    platformIntegrationType: number;
    feTokenId: number;
    recommendedGamesEnabled: boolean;
    recommendedGamesCredentialsId: number;
    trackingUrlId: number;
  };
  pamConnections: PamInstanceConnection[];
  t1Features: {
    homePageEnabled: boolean;
    pnpEnabled: boolean;
    useComDomainInfoPage: boolean;
    cookieBotEnabled: boolean;
  };
};

export const segmentIdToName = Object.freeze({
  [SegmentEnum.ASG_MANAGED]: 'ASG-managed',
  [SegmentEnum.SHARED_MANAGED]: 'Shared-managed',
  [SegmentEnum.SELF_MANAGED]: 'Self-managed',
});

export type PutBrandSettingsAuthorization = {
  siteId: number;
  features: string[][];
  verticals: string[];
};

export type PutBrandSettingsAuthorizationRequest = {
  siteId: number;
  features: FeatureSegment[];
  verticalIdsEnabled: number[];
};

export type PutBrandSettingsIntegrationsRequest = {
  siteId: number;
  pamConnections: PamInstanceConnection[];
};

