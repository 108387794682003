import {
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import {
  GetLocalizationPropertiesFields,
  GetNetworkExperienceFields,
  GetPamInstancesFields,
  NetworkEditorState,
} from './types';
import {
  fetchLocalizationProperties,
  fetchNetworkExperience,
  fetchPamInstances,
} from './actions';

const initialState: NetworkEditorState = {
  localizationProperties: null,
  pamInstancesProperties: null,
  networkExperienceProperties: null,
};

export const networkEditorSlice = createSlice({
  name: 'networkEditor',
  initialState,
  reducers: {
    clearLocalizationPropertiesState(state) {
      state.localizationProperties = null;
    },
    clearPamInstancesState(state) {
      state.pamInstancesProperties = null;
    },
    clearNetworkExperienceState(state) {
      state.networkExperienceProperties = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchLocalizationProperties.fulfilled, (
      state: NetworkEditorState,
      action: PayloadAction<GetLocalizationPropertiesFields | null>,
    ) => {
      if (action.payload !== null) {
        state.localizationProperties = action.payload;
      }
    });
    builder.addCase(fetchPamInstances.fulfilled, (
      state: NetworkEditorState,
      action: PayloadAction<GetPamInstancesFields | null>,
    ) => {
      if (action.payload !== null) {
        state.pamInstancesProperties = action.payload;
      }
    });
    builder.addCase(fetchNetworkExperience.fulfilled, (
      state: NetworkEditorState,
      action: PayloadAction<GetNetworkExperienceFields | null>,
    ) => {
      if (action.payload !== null) {
        state.networkExperienceProperties = action.payload;
      }
    });
  },
});
