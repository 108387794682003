import {
  FC,
  MouseEventHandler,
} from 'react';
import './ActionButton.scss';
import classNames from 'classnames';

import { Icon } from 'components/shared/Icon/Icon';
import {
  BgShapeEnum,
  IconEnums,
} from 'common/IconEnums';
import { SizeEnums } from 'common/SizeEnums';

interface Props {
  iconName: IconEnums;
  iconColor?: string;
  iconSize?: SizeEnums;
  bgSize?: SizeEnums;
  bgColor?: string;
  bgShape?: BgShapeEnum;
  hasNotifications?: boolean;
  rotateIconDegree?: number;
  onClickHandler?: MouseEventHandler;
  isDisabled?: boolean;
  isLocked?: boolean;
  isClickable?: boolean;
  isSelected?: boolean;
  color?: string;
  className?: string;
  circleIconClassName?: string;
}

export const ActionButton: FC<Props> = ({
  iconName,
  iconColor,
  iconSize,
  bgSize,
  bgColor,
  bgShape,
  hasNotifications = false,
  rotateIconDegree,
  onClickHandler,
  isDisabled,
  isLocked,
  isClickable,
  isSelected,
  color,
  className = '',
  circleIconClassName = '',
}) => {
  const actionButtonContainer = classNames({
    'action-button': true,
    [`action-button--${color}`]: color,
    [`action-button--${bgSize}`]: bgSize,
    [`action-button--${bgShape}`]: bgShape,
    ['action-button--disabled']: isDisabled,
    ['action-button--disabled-selected']: isSelected && isDisabled,
    ['action-button--locked']: isLocked,
    ['action-button__clickable']: isClickable && onClickHandler,
    ['action-button__clickable action-button__clickable--selected']: isSelected,
    [className]: className,
  });

  const imageCircleIconClassName = classNames({
    'circle-icon': true,
    [circleIconClassName]: circleIconClassName,
    [`circle-icon--${iconSize}`]: iconSize,
  });

  return (
    <div
      className={actionButtonContainer}
      onClick={onClickHandler}
      style={{ 'backgroundColor': bgColor }}
      role="presentation"
    >
      {hasNotifications &&
        <div className="notification-container">
          <div className="notification-container__notification-circle"></div>
        </div>
      }
      <Icon
        iconName={iconName}
        bgColor={iconColor}
        rotateIconDegree={rotateIconDegree}
        iconSize={iconSize}
      />
      {isLocked && (
        <div className={imageCircleIconClassName}>
          <div className="circle-icon__image">
            <Icon
              iconName={IconEnums.LOCK}
              iconSize={SizeEnums.LARGE}
              bgColor={`${isLocked ? '#BCC2C8' : '#212529'}`}
            />
          </div>
        </div>
      )}
    </div>
  );
};
