import './SidebarLink.scss';
import { FC } from 'react';
import { Link } from '../Link/Link';
import classNames from 'classnames';
import { useSidebarLink } from 'hooks/use-sidebar-link';
import { AppLinks } from 'router/uri';

interface Props {
  text: string;
  url: string;
  icon: string;
  activeIcon?: string;
  isMinimize: boolean;
  onBeforeTabChange: () => Promise<boolean>;
  isBrandItem?: boolean;
  isHovered: boolean;
}

export const SidebarLink: FC<Props> = ({
  text,
  url,
  icon,
  activeIcon,
  isMinimize,
  onBeforeTabChange,
  isBrandItem,
  isHovered,
}) => {
  const { iaActiveLink, handleMenuClick } = useSidebarLink(url, onBeforeTabChange, isBrandItem);

  const linkClassName = classNames({
    'sidebar-link': true,
    'sidebar-link--minimize': isMinimize,
    'sidebar-link--minimize--hover': isHovered,
  });

  return (
    <Link
      to={url}
      className={linkClassName}
      activeClassName="sidebar-link--active"
      onClick={async (e) => handleMenuClick(e)}
      title={text}
    >
      <div
        className="sidebar-link-icon"
        style={{ 'background': `var(--${iaActiveLink ? activeIcon : icon}) no-repeat center` }}></div>
      <div
        className={classNames(
          'sidebar-link-text',
          url === AppLinks.HOME && iaActiveLink && 'sidebar-link-text--home-active',
        )}
      >
        {text}
      </div>
    </Link>
  );
};
