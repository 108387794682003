import './NewBrandItem.scss';
import { FC } from 'react';
import { Icon } from 'components/shared/Icon/Icon';
import { IconEnums } from 'common/IconEnums';
import { DateService } from 'services/DateService';
import classNames from 'classnames';
import { BrandTypesEnum } from 'store/brands/types';

export interface NewBrandItemProps {
  brandName: string;
  brandType: BrandTypesEnum;
  date?: string;
}

export const NewBrandItem: FC<NewBrandItemProps> = ({ brandName, brandType, date = '' }) => {
  const itemDate = DateService.getDate(date);

  return (
    <div className="new-brand-item">
      <div className="new-brand-item-info">
        <div className={classNames('new-brand-item-info__icon', `new-brand-item-info__icon--${brandType}`)}></div>
        <div className="new-brand-item-info__brand-name">{brandName}</div>
      </div>

      {date && <div className="new-brand-item-date">
        <Icon iconName={IconEnums.CLOCK} />
        <div className="new-brand-item-date__date">{itemDate}</div>
      </div>}
    </div>
  );
};
