import {
  ReactNode,
  useCallback,
  useState,
} from 'react';
import { useCreateFormLocalValidator } from '../use-create-form-local-validator';

export type TabsValidator = ReturnType<typeof useCreateTabsValidator>;

/**
 * Hook that handles tabs state depending on underlying forms validation.
 */
export const useCreateTabsValidator = () => {
  const {
    submitButtonIsLoading,
    setSubmitButtonIsLoading,
    submitButtonIsDisabled,
    setSubmitButtonIsDisabled,
    submitButtonIsSucceed,
    setSubmitButtonIsSucceed,
    handleSubmit,
    setHandleSubmit,
    onBeforeSubmit,
    setOnBeforeSubmit,
    checkBeforeSubmit,
    setCheckBeforeSubmit,
    resetState,
  } = useCreateFormLocalValidator();

  // underlying form puts own handler to indicate if current tab/sub-tab can be changed
  // tab change will be cancelled if this handler returns false
  const [onBeforeTabChange, setOnBeforeTabChange] = useState(() => {
    return () => {
      return Promise.resolve(true);
    };
  });
  const [actions, setActions] = useState<ReactNode | null>(null);

  const reinitialedResetState = useCallback(() => {
    resetState();
    setOnBeforeTabChange(() => {
      return () => {
        return Promise.resolve(true);
      };
    });
  }, []);

  return {
    submitButtonIsLoading,
    setSubmitButtonIsLoading,
    submitButtonIsDisabled,
    setSubmitButtonIsDisabled,
    submitButtonIsSucceed,
    setSubmitButtonIsSucceed,
    handleSubmit,
    setHandleSubmit,
    onBeforeSubmit,
    setOnBeforeSubmit,
    checkBeforeSubmit,
    setCheckBeforeSubmit,
    onBeforeTabChange,
    setOnBeforeTabChange,
    setActions,
    actions,
    resetState: reinitialedResetState,
  };
};
