import './Initials.scss';
import {
  FC,
  useEffect,
  useState,
} from 'react';
import classNames from 'classnames';

interface Props {
  userName: string;
  className?: string;
}

export const Initials: FC<Props> = ({ userName, className }) => {
  const [initials, setInitials] = useState<string[]>([]);

  useEffect(() => {
    const userInitials = userName?.split(' ').map(item => item[0]);

    setInitials(userInitials?.splice(0, 2));
  }, [userName]);

  return (
    <div className={classNames('user-initials', className)} title={userName}>{initials}</div>
  );
};
