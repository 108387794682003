import { FC } from 'react';
import { ReactComponent as LogoImg } from 'assets/images/cms-logo.svg';
import './Logo.scss';
import { Icon } from '../Icon/Icon';
import { IconEnums } from 'common/IconEnums';
import classNames from 'classnames';
import { useMenuLogoProps } from 'hooks/use-menu-logo-props';

interface Props {
  isMinimizeHoverMode?: boolean;
}

export const Logo: FC<Props> = ({ isMinimizeHoverMode }) => {
  const { setIsMinimizeMenuByLogo, isMinimizeMode, isBrandsListAvailable } = useMenuLogoProps();

  return (
    <div className={classNames('main-logo', isMinimizeMode && 'main-logo--minimize')}>
      <div className="main-logo__content">
        <div
          onClick={setIsMinimizeMenuByLogo}
          role="presentation"
          className={classNames(
            'main-logo__content__burger',
            isMinimizeMode && 'main-logo__content__burger--minimize',
          )}
        >
          <Icon iconName={IconEnums.BURGER_MENU} />
        </div>
        {!isBrandsListAvailable && <div className={classNames(
          'main-logo__content__icon',
          isMinimizeMode && 'main-logo__content__icon--minimize',
          isMinimizeHoverMode && 'main-logo__content__icon--hovered',
        )}>
          <LogoImg />
        </div>}
      </div>
    </div>
  );
};
