import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  types,
  GetBrandAssets,
  GetBrandAssetsResponse,
  BrandAsset,
  PostBrandAsset,
  PostBrandAssetResponse,
  PatchBrandAsset,
  PatchBrandAssetResponse,
  GetLogosAssetsResponse,
  GetLogosAssetsFields,
  PutLogosRequest,
  DeleteBrandAsset,
  DeleteBrandAssetResponse,
  GetBrandAssetsRequest,
  GetBrandIcons,
  GetBrandIconsResponse,
  GetBrandIconsRequest,
  PutBrandIconsResponse,
  PutIconsRequest,
} from './types';
import { AsyncThunkParams } from '../app/types';
import { endpoints } from '../../config/endpoints';
import { BackendResponseService } from '../../services/BackendResponseService';

export const fetchBrandAssets = createAsyncThunk<
GetBrandAssets | null,
AsyncThunkParams<GetBrandAssetsResponse, GetBrandAssetsRequest>
>(
  types.ASSETS_IMAGES_FETCH,
  async (
    { execute, payload },
  ) => {
    try {
      if (payload) {
        const params = new URLSearchParams('');

        // optional tabName param
        if (payload.tabName) {
          params.append('tabName', payload.tabName.toString());
        }
        // optional systemGroupId param
        if (payload.systemGroupId) {
          params.append('systemGroupId', payload.systemGroupId.toString());
        }

        const urlParams = params.toString() ? `?${params.toString()}` : '';

        const url = `${endpoints.brandAssetsGetImages.replace(
          ':siteId', payload.siteId.toString())}${urlParams}`;

        const result = await execute(
          'GET',
          url,
        );
        return BackendResponseService.getBackendResponseData(result);
      } else {
        return null;
      }
    } catch (err) {
      console.error(types.ASSETS_IMAGES_FETCH, err);
      throw err;
    }
  },
);

export const fetchBrandIcons = createAsyncThunk<
GetBrandIcons | null,
AsyncThunkParams<GetBrandIconsResponse, GetBrandIconsRequest>
>(
  types.ASSETS_ICONS_FETCH,
  async (
    { execute, payload },
  ) => {
    try {
      if (payload) {
        const params = new URLSearchParams('');

        if (payload.tabName) {
          params.append('tabName', payload.tabName.toString());
        }

        const result = await execute(
          'GET',
          `${endpoints.brandAssetsGetIcons.replace(':siteId', payload.siteId)}?${params}`,
        );
        return BackendResponseService.getBackendResponseData(result);
      } else {
        return null;
      }
    } catch (err) {
      console.error(types.ASSETS_ICONS_FETCH, err);
      throw err;
    }
  },
);

export const postBrandAsset = createAsyncThunk<
PostBrandAsset | null,
AsyncThunkParams<PostBrandAssetResponse, Partial<BrandAsset>>
>(
  types.ASSETS_IMAGES_ASSET_POST,
  async (
    { execute, payload },
  ) => {
    try {
      if (payload) {
        const result = await execute('POST', endpoints.brandAssetsPostAsset, payload);
        return BackendResponseService.getBackendResponseData(result);
      } else {
        return null;
      }
    } catch (err) {
      console.error(types.ASSETS_IMAGES_ASSET_POST, err);
      throw err;
    }
  },
);

export const patchBrandAsset = createAsyncThunk<
PatchBrandAsset | null,
AsyncThunkParams<PatchBrandAssetResponse, Partial<BrandAsset>>
>(
  types.ASSETS_IMAGES_ASSET_PATCH,
  async (
    { execute, payload },
  ) => {
    try {
      if (payload) {
        const result = await execute('PATCH', endpoints.brandAssetsPatchAsset, payload);
        return BackendResponseService.getBackendResponseData(result);
      } else {
        return null;
      }
    } catch (err) {
      console.error(types.ASSETS_IMAGES_ASSET_POST, err);
      throw err;
    }
  },
);

export const deleteBrandAsset = createAsyncThunk<
DeleteBrandAsset | null,
AsyncThunkParams<DeleteBrandAssetResponse, { assetId: number }>
>(
  types.ASSETS_IMAGES_ASSET_DELETE,
  async (
    { execute, payload },
  ) => {
    try {
      if (payload) {
        const result = (await execute('DELETE', endpoints.brandAssetsDeleteAsset
          .replace(':assetId', payload.assetId.toString()), payload));
        return BackendResponseService.getBackendResponseData(result);
      } else {
        return null;
      }
    } catch (err) {
      console.error(types.ASSETS_IMAGES_ASSET_DELETE, err);
      throw err;
    }
  },
);

export const fetchLogosAssets = createAsyncThunk<
GetLogosAssetsFields | null,
AsyncThunkParams<GetLogosAssetsResponse, { siteId: number }>
>(
  types.ASSETS_LOGOS_FETCH,
  async (
    { execute, payload },
  ) => {
    try {
      if (payload) {
        const result = await execute(
          'GET',
          endpoints.brandAssetsGetLogos.replace(':siteId', payload.siteId.toString() as string),
        );
        return BackendResponseService.getBackendResponseData(result);
      } else {
        return null;
      }
    } catch (err) {
      console.error(types.ASSETS_LOGOS_FETCH, err);
      throw err;
    }
  },
);

export const putLogosAssets = createAsyncThunk<
void | null,
AsyncThunkParams<void, PutLogosRequest>
>(
  types.ASSETS_LOGOS_PUT,
  async (
    { execute, payload },
  ) => {
    try {
      await execute(
        'PUT',
        endpoints.brandAssetsPutLogos,
        payload,
      );
    } catch (err) {
      console.error(types.ASSETS_LOGOS_PUT, err);
      throw err;
    }
  },
);

export const putBrandIcons = createAsyncThunk<
void | null,
AsyncThunkParams<PutBrandIconsResponse, PutIconsRequest>
>(
  types.ASSETS_ICONS_PUT,
  async (
    { execute, payload },
  ) => {
    try {
      await execute(
        'PUT',
        endpoints.brandAssetsPutIcons,
        payload,
      );
    } catch (err) {
      console.error(types.ASSETS_ICONS_PUT, err);
      throw err;
    }
  },
);
