import React, {
  FC,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react';
import { Outlet } from 'react-router';
import { Navigate } from 'react-router-dom';
import { useAppSelector } from '../../store/hooks';
import { MenusGuardsEnum } from '../enums/menus-guards.enum';

type Props = PropsWithChildren & {
  menuGuardName: MenusGuardsEnum;
  redirectPath?: string;
};

// Protection from direct link entry
export const MenusGuard: FC<Props> = ({
  menuGuardName,
  redirectPath = '/',
  children,
}) => {
  const { menusGuards } = useAppSelector((state) => state.menus);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    // Check if menusGuards are loaded
    if (menusGuards) {
      setIsLoaded(true);
    }
  }, [menusGuards]);

  if (isLoaded && menusGuards) {
    const isAllow = menusGuards[menuGuardName];

    if (!isAllow) {
      return <Navigate to={redirectPath} replace />;
    } else {
      return <>{children ?? <Outlet />}</>;
    }
  } else {
    return null;
  }
};
