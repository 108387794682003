import {
  FC,
  MouseEventHandler,
  Ref,
} from 'react';
import classNames from 'classnames';
import './AddToListBtn.scss';
import { Icon } from 'components/shared/Icon/Icon';
import { SizeEnums } from 'common/SizeEnums';
import { IconEnums } from 'common/IconEnums';

interface Props {
  text: string;
  onClick: MouseEventHandler<HTMLElement>;
  disabled?: boolean;
  buttonRef?: Ref<HTMLDivElement>;
}

export const AddToListBtn: FC<Props> = ({ text, onClick, disabled, buttonRef }) => {
  const addToListBtnClassNames = classNames({
    'add-btn-container': true,
    'add-btn-container--disabled': disabled,
  });

  return (
    <div className={addToListBtnClassNames} onClick={onClick} role="presentation" ref={buttonRef}>
      <div className="add-btn-circle">
        <Icon iconName={IconEnums.PLUS} iconSize={SizeEnums.SMALL_MEDIUM} bgColor="white" />
      </div>
      <div className="add-btn-text">{text}</div>
    </div>
  );
};
