import { useCallback } from 'react';
import {
  useAppActions,
  useAppDispatch,
} from 'store/hooks';
import { useFetchWithMsal } from './use-fetch-with-msal';
import {
  UserSessionResponse,
  UserSessionValues,
} from '../store/app/types';
import { useNavigate } from 'react-router-dom';

export const useCmsActiveUser = (extId: string) => {
  const dispatch = useAppDispatch();
  const {
    getUserSession,
    handleNavigationMode,
    handleSetSiteId,
  } = useAppActions();
  const { execute } = useFetchWithMsal();
  const navigate = useNavigate();

  const doGetActiveUserRequest = async () => {
    const data = await dispatch(getUserSession({ execute }));
    if (data.payload) {
      const { session } = data.payload as UserSessionResponse;
      const sessionData = session.userSessionValue as UserSessionValues;

      dispatch(handleNavigationMode(sessionData.isMinimizeMode || false));

      if (sessionData.currentSiteId) {
        dispatch(handleSetSiteId(sessionData.currentSiteId));
      }

      // handle redirect to last visited module
      const currentPath = window.location.pathname;
      if (sessionData.pathname && currentPath === '/') {
        navigate(sessionData.pathname);
      }
    }
  };

  return {
    doGetActiveUserRequest: useCallback(doGetActiveUserRequest, [execute, extId]),
  };
};
