import { BackendResponse } from '../app/types';

export const types = Object.freeze({
  ASSETS_IMAGES_FETCH: '[Assets] ASSETS_IMAGES_FETCH',
  ASSETS_IMAGES_ASSET_POST: '[Assets] ASSETS_IMAGES_ASSET_POST',
  ASSETS_IMAGES_ASSET_PATCH: '[Assets] ASSETS_IMAGES_ASSET_PATCH',
  ASSETS_IMAGES_ASSET_DELETE: '[Assets] ASSETS_IMAGES_ASSET_DELETE',
  ASSETS_LOGOS_FETCH: '[Assets] ASSETS_LOGOS_FETCH',
  ASSETS_LOGOS_PUT: '[Assets] ASSETS_LOGOS_PUT',
  ASSETS_ICONS_FETCH: '[Assets] ASSETS_ICONS_FETCH',
  ASSETS_ICONS_PUT: '[Assets] ASSETS_ICONS_PUT',
});

export type AssetsState = {
  images: BrandAsset[] | null;
  logos: BrandLogosAsset | null;
  icons: BrandAsset[] | null;
  experienceSettings: Experience[] | null;
};

export type GetBrandAssetsResponse = BackendResponse<GetBrandAssets>;
export type PostBrandAssetResponse = BackendResponse<PostBrandAsset>;
export type PatchBrandAssetResponse = BackendResponse<PatchBrandAsset>;
export type DeleteBrandAssetResponse = BackendResponse<DeleteBrandAsset>;
export type PutBrandAssetsResponse = BackendResponse<PutBrandAsset>;

export type GetBrandAssetsRequest = {
  siteId: number;
  tabName?: string;
  systemGroupId?: number;
};

export type BrandAsset = {
  brandAssetName: string;
  brandAssetDescription?: string;
  brandAssetMode: number;
  siteId: number | null;
  systemGroupId?: number;
  brandAssetTypeId?: number | null;
  brandAssetStateId?: number | null;
  brandAssetScheduleId?: number | null;
  brandAssetFileId: number | null;
  brandAssetId?: number;
  brandAssetFileUrl?: string | null;
  brandAssetFileThumbnailUrl?: string | null;
  brandAssetFileName?: string;
};

export type GetBrandAssets = {
  images: BrandAsset[];
};

export type PostBrandAsset = {
  createdBrandAssetId: number;
};

export type PatchBrandAsset = {
  updatedBrandAssetId: number;
};

export type DeleteBrandAsset = {
  deletedBrandAssetId: number;
};

export type GetLogosAssetsResponse = BackendResponse<GetLogosAssetsFields>;

export type ScheduledLogo = BrandAsset & {
  brandAssetScheduleDescription: string;
  from: string;
  to: string;
};

export type BrandLogosAsset = {
  logos: BrandAsset[];
  scheduledLogos: ScheduledLogo[];
};

export type GetLogosAssetsFields = BrandLogosAsset;

export type PutLogosRequest = {
  assets: (Partial<BrandAsset>)[];
  deletedAssetsIds: number[];
  assetsSchedules: ScheduledLogo[];
  deletedAssetsSchedulesIds: number[];
};

export type PutBrandAsset = {
  updatedBrandAssets: boolean;
};

export type GetBrandIconsResponse = BackendResponse<GetBrandIcons>;
export type PutBrandIconsResponse = BackendResponse<PutBrandIcons>;

export type  GetBrandIcons = {
  experienceSettings: Experience[];
  icons: BrandAsset[];
};

export type GetBrandIconsRequest = {
  siteId: string;
  tabName: string;
};

export type PutIconsRequest = {
  assets: (Partial<BrandAsset>)[];
  deletedAssetsIds: number[];
};

export type PutBrandIcons = {
  updatedBrandAssets: boolean;
};

type Experience = {
  brandExperienceId: number;
  brandExperienceName: string;
  siteId: number;
  systemGroupId: number;
  translationGroupId: null;
  brandExperienceValue: number;
};
