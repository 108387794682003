import { useCallback } from 'react';
import {
  useAppActions,
  useAppDispatch,
} from 'store/hooks';
import { useFetchWithMsal } from './use-fetch-with-msal';

export const useCmsPermissionsMap = () => {
  const dispatch = useAppDispatch();
  const {
    getPermissionsMap,
  } = useAppActions();
  const { execute } = useFetchWithMsal();
  const doGetPermissionsMapRequest = () => {
    dispatch(getPermissionsMap({ execute }));
  };

  return {
    doGetPermissionsMapRequest: useCallback(doGetPermissionsMapRequest, [execute]),
  };
};
