import './App.scss';
import { FC } from 'react';
import { MsalProvider } from '@azure/msal-react';
import { useLoadMsalInstance } from 'auth/hooks/use-load-msal-instance';
import { AppContent } from './AppContent';
import { useAppConfig } from '../../hooks/use-app-config';

const App: FC = () => {
  const msalInstance = useLoadMsalInstance();
  // load env variables
  useAppConfig();

  if (!msalInstance) {
    return null;
  }

  return (
    <MsalProvider instance={msalInstance}>
      <AppContent />
    </MsalProvider>
  );
};

export default App;
