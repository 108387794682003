import { PermissionMap } from '../auth/types';

export const PermissionsService = {
  checkPermissions(permissionsMap: PermissionMap[], permissions: string[], siteId: number | null = null) {
    return permissions.every(permission => permissionsMap
      .some(permissionMapItem => permissionMapItem.permissionName === permission
            && permissionMapItem.permissionValue
            && (!siteId || permissionMapItem.brands?.some(
              permissionBrand => permissionBrand.siteId === siteId,
            )),
      ),
    );
  },
};
