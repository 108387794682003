import './MenuItem.scss';
import classNames from 'classnames';
import {
  FC,
  SyntheticEvent,
} from 'react';
import { Icon } from 'components/shared/Icon/Icon';
import { IconEnums } from 'common/IconEnums';

export interface MenuItemProps {
  id?: string | number;
  text?: string;
  icon?: IconEnums;
  colorIcon?: string;
  isDangerOperation?: boolean;
  onClick?: (e: SyntheticEvent) => void;
  toggleMenu?: () => void;
}

export const MenuItem: FC<MenuItemProps> = ({
  text,
  icon,
  colorIcon,
  isDangerOperation = false,
  onClick,
  toggleMenu,
}) => {
  const itemClassName = classNames({
    'menu-item-container': true,
    'menu-item-container--is-danger': isDangerOperation,
  });

  const bgColor = isDangerOperation ? 'var(--red)' : '';

  const onClickHandle = (event: SyntheticEvent<HTMLElement>) => {
    onClick?.(event);
    toggleMenu?.();
  };

  return (
    <div className={itemClassName} onClick={onClickHandle} role="presentation">
      {colorIcon &&
      <div className="menu-item-color-icon" style={{ 'background': `var(--${colorIcon}) no-repeat center` }}></div>}
      <div className="menu-item-text" title={text}>{text}</div>
      {icon && <Icon iconName={icon} bgColor={bgColor} />}
    </div>
  );
};
