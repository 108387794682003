import { FC } from 'react';
import './MajorLoadingNotification.scss';
import { CircleSpinner } from '../CircleSpinner/CircleSpinner';
import { useTimeLeft } from 'hooks/use-time-left';

interface Props {
  seconds: number;
}

export const MajorLoadingNotification: FC<Props> = ({ seconds }) => {
  const { timeLeft } = useTimeLeft(seconds);

  return (
    <div className="loading-notification">
      <CircleSpinner className="loading-notification__spinner" />

      <div className="loading-notification__timer">{`00: ${timeLeft}`}</div>
      <div>Applying changes...</div>
    </div>
  );
};
