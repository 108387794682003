import {
  BackendResponse,
  Company,
  Country,
  RegulationWithCountry,
} from '../app/types';

export const types = Object.freeze({
  LICENSES_PROPS_FETCH: '[Regulations] LICENSES_PROPS_FETCH',
  LICENSES_PROPS_PUT: '[Regulations] LICENSES_PROPS_PUT',
  REGULATED_FEATURES_PROPS_FETCH: '[Regulations] REGULATED_FEATURES_FETCH',
  REGULATED_FEATURES_PROPS_PUT: '[Regulations] REGULATED_FEATURES_PUT',
});

export type RegulationsState = {
  licenses: GetLicensesFields | null;
  regulatedFeatures: GetRegulatedFeaturesFields | null;
};

export type GetLicensesResponse = BackendResponse<GetLicensesFields>;
export type PutLicensesResponse = BackendResponse<boolean>;
export type GetRegulatedFeaturesResponse = BackendResponse<GetRegulatedFeaturesFields>;
export type PutRegulatedFeaturesResponse = BackendResponse<boolean>;

export type GetRegulatedFeaturesRequest = {
  companyId: number | null;
};

export type GetLicensesFields = {
  regulations: RegulationWithCountry[];
  deleteRegulations: number[];
  licenses: License[];
  deleteLicenses: number[];
};

export type GetRegulatedFeatureType = RegulatedFeature & {
  regulatedFeatureId?: number;
};

export type GetRegulatedFeaturesFields = {
  regulatedFeatures: GetRegulatedFeatureType[];
  featuresRegulatedDictionary: FeaturesRegulatedDictionary[];
  regulationsWithCountries:
  {
    regulationId: number;
    regulationName: string;
    regulationCode: string;
    countries: Country[];
  }[];
  companiesAvailable: Company[];
};

export type License = {
  licenseId: number;
  regulationId: number;
  licenseType: number;
  companyName: string;
  address: string;
  licenseNumber: string;
};

export type RegulatedFeatureIdBaseType = {
  regulatedFeatureId: number;
};

export type RegulatedFeature = {
  companyId: number | null;
  featureRegulatedDictionaryId: number;
  regulationId: number;
  countryId: number | null;
  regulatedFeatureCountryOther: boolean | null;
  isEnabled: boolean;
};

export type FeaturesRegulatedDictionary = {
  featureRegulatedDictionaryId: number;
  featureRegulatedDictionaryName: string;
};

export type PutRegulatedFeatureType = RegulatedFeature | RegulatedFeatureIdBaseType;

export type PutRegulatedFeatureRequest = {
  regulatedFeatures: PutRegulatedFeatureType[];
  deleteRegulatedFeatures: number[];
};

export type PutLicensesRequest = {
  regulations: PutRegulation[];
  deleteRegulations: number[];
  licenses: PutLicense[];
  deleteLicenses: number[];
};

export type PutRegulation = {
  regulationId?: RegulationWithCountry['regulationId'];
  countriesIds: number[];
} & Pick<RegulationWithCountry, 'regulationName' | 'regulationCode'>;

export type PutLicense = {
  licenseId?: License['licenseId'];
} & Pick<License, 'regulationId' | 'licenseType' | 'companyName' | 'address' | 'licenseNumber'>;
