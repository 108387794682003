import './DotsSpinner.scss';
import { FC } from 'react';
import classNames from 'classnames';

interface Props {
  className?: string;
}

export const DotsSpinner: FC<Props> = ({ className }) => {
  return (
    <div className={classNames('loading', className)}>
      <span className="loading__dot"></span>
      <span className="loading__dot"></span>
      <span className="loading__dot"></span>
    </div>
  );
};
