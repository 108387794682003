import { FC } from 'react';

import './Icon.scss';
import classNames from 'classnames';
import { SizeEnums } from 'common/SizeEnums';
import { IconEnums } from 'common/IconEnums';

interface Props {
  iconName: IconEnums | string;
  iconSize?: SizeEnums;
  bgColor?: string;
  rotateIconDegree?: number;
}

export const Icon: FC<Props> = ({ iconName, iconSize, bgColor, rotateIconDegree }) => {
  const iconClassName = classNames({
    'icon-container': true,
    [`icon-container--${iconName}`]: iconName,
    [`icon-container--${iconSize}`]: iconSize,
    'icon-container--icon-color': bgColor,
    [`icon-container--rotate-${rotateIconDegree}`]: rotateIconDegree,
  });

  return (
    <svg className={iconClassName} style={{ 'background': bgColor }}></svg>
  );
};
