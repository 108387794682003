import { FC } from 'react';
import './ChunkLoader.scss';
import { useCreateTabsValidator } from 'hooks/tabs/use-create-tabs-validator';
import { NavigationSidebar } from '../NavigationSidebar/NavigationSidebar';
import { useAppSelector } from 'store/hooks';
import classNames from 'classnames';

export const ChunkLoader: FC = () => {
  const tabValidator = useCreateTabsValidator();
  const { isMinimizeMode } = useAppSelector(state => state.app);

  return (
    <div className="chunk-loader">
      <div className="content">
        <div className={classNames('content__navbar', isMinimizeMode && 'content__navbar--minimize')}>
          <NavigationSidebar onBeforeTabChange={tabValidator.onBeforeTabChange} isChunk={true} />
        </div>

        <div className="content__main-block">
          <div className="content__main-block__header"></div>
          <div className="content__main-block__info"></div>
        </div>
      </div>
    </div>
  );
};
