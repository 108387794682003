import {
  FC,
  useRef,
} from 'react';

import './NewModularBrandModal.scss';

import { Modal } from 'components/shared/Modal/Modal';
import {
  NewBrandItem,
  NewBrandItemProps,
} from 'components/modules/Brands/NewBrandItem/NewBrandItem';
import { useOnClickOutside } from 'hooks/use-on-click-outside';
import { SystemCta } from 'components/shared/SystemCta/SystemCta';
import { BrandTypesEnum } from 'store/brands/types';

interface Props {
  toggleModal: () => void;
  atomBrands: NewBrandItemProps[];
}

export const NewModularBrandModal: FC<Props> = ({ toggleModal, atomBrands }) => {
  const ref = useRef(null);
  useOnClickOutside(ref, toggleModal);

  return (
    <div className="brands-modal-container">
      <Modal
        className="brands-modal-window"
        forwardedRef={ref}
      >
        <div className="brands-modal-text">
          <div className="brands-modal-text__title">Starting template</div>
          <div className="brands-modal-text__subtitle">Select a template to copy its content</div>
        </div>
        <div className="brands-modal-list">
          <NewBrandItem brandName="Start from scratch" brandType={BrandTypesEnum.CUSTOMER_BRAND} />
          {atomBrands.map(({ brandType, brandName, date }, index) =>
            <NewBrandItem key={index} brandType={brandType} brandName={brandName} date={date} />)}
        </div>
        <div className="brands-modal-window__actions">
          <SystemCta text="Create" onClick={() => alert('New Modular brand created')} />
          <SystemCta text="Cancel" onClick={toggleModal} className="cancel-button" />
        </div>
      </Modal>
    </div>
  );
};
