import './NavbarUserMenu.scss';
import { FC } from 'react';
import { CmsUserMenu } from '../CmsUserMenu/CmsUserMenu';
import classNames from 'classnames';
import { Initials } from '../Initials/Initials';
import { useUserMenuProps } from 'hooks/use-user-menu-props';

interface Props {
  isMinimize: boolean;
  className?: string;
}

export const NavbarUserMenu: FC<Props> = ({ isMinimize, className = '' }) => {
  const { menuItems, isMenuOpen, menuToggle, user, userName, build } = useUserMenuProps();

  const containerClassName = classNames({
    'user-initials-container': true,
    'user-initials-container--mini': isMinimize,
    [className]: className,
  });

  return (
    <div className={containerClassName} onClick={menuToggle} role="presentation">
      <div className="user-initials-info">
        <Initials userName={user?.userName || ''} className="user-initials-info__avatar" />
        <div className="user-initials-info__text">{userName()}</div>
      </div>

      {isMenuOpen && <CmsUserMenu
        user={user}
        toggleMenu={menuToggle}
        menuList={menuItems}
        version={build}
      />}
    </div>
  );
};
