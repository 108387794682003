import { useLocation } from 'react-router-dom';

/**
 * Hook to get current module path
 *
 * - for brand level module - keeps /module_name/:site_id
 * - for network level module - keeps /module_name
 */
export function useCurrentModulePath() {
  const { pathname } = useLocation();

  const pathParts = pathname.split('/');

  // detecting brand level module. Brand level module has site_id in path
  const isBrandLevelModule = pathParts[2] && !isNaN(Number(pathParts[2]));

  return {
    modulePath: isBrandLevelModule ? `/${pathParts[1]}/${pathParts[2]}` : `/${pathParts[1]}`,
    isBrandLevelModule,
  };
}
