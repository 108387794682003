import { FC } from 'react';
import { SidebarLink } from '../SidebarLink/SidebarLink';
import {
  BrandEntries,
  SidebarItem,
} from '../../layouts/NavigationSidebar/types';
import { useAppSelector } from 'store/hooks';
import { PermissionsService } from 'services/PermissionsService';
import { useCurrentPermissionsBrand } from 'auth/hooks/use-current-permissions-brand';

interface Props {
  items: BrandEntries;
  isMinimizeMenuByScreenSize: boolean;
  onBeforeTabChange: () => Promise<boolean>;
  isHovered: boolean;
}

export const SidebarBlock: FC<Props> = ({
  items,
  isMinimizeMenuByScreenSize,
  onBeforeTabChange,
  isHovered,
}) => {
  const { siteId } = useCurrentPermissionsBrand();
  const { permissionsMap } = useAppSelector((state) => state.app);
  const { menusGuards } = useAppSelector((state) => state.menus);

  if (!menusGuards) {
    return null;
  }

  return (
    <>
      {
          Object.values(items).map((block: SidebarItem[], index) =>
            <div className="navigation-sidebar__links__block" key={index}>
              {block.map(({ url, text, icon, activeIcon, permissions, isBrandItem, menuGuard }) => {
                const isAllowByPermission = !permissions || PermissionsService.checkPermissions(
                  permissionsMap,
                  permissions,
                  siteId,
                );
                const isAllowByGuard = !menuGuard || menusGuards[menuGuard];
                const isAllow = isAllowByPermission && isAllowByGuard;
                return isAllow ? (<SidebarLink
                  key={url}
                  text={text}
                  url={url}
                  isMinimize={isMinimizeMenuByScreenSize}
                  icon={icon}
                  activeIcon={activeIcon}
                  onBeforeTabChange={onBeforeTabChange}
                  isBrandItem={isBrandItem}
                  isHovered={isHovered}
                />) : null;
              })}
            </div>,
          )
        }
    </>
  );
};
