import {
  AuthenticationResult,
  EventType,
  IPublicClientApplication,
  PublicClientApplication,
} from '@azure/msal-browser';
import { msalConfig } from 'config/auth';
import { useAppSelector } from '../../store/hooks';
import { useMemo } from 'react';

export const useLoadMsalInstance = () => {
  const { azureAD } = useAppSelector((state) => state.app);

  return useMemo(() => {
    let msalInstance = null as never as IPublicClientApplication;

    if (azureAD.clientId && azureAD.tenantId) {
      /**
       * MSAL should be instantiated outside the component tree to prevent it from being re-instantiated on re-renders.
       * For more, visit: https://github.com/
       * AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
       * AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/v2-migration.md
       * #migrating-from-msal-2x-to-msal-3x
       */
      const msalConfigFilled = {
        ...msalConfig,
        auth: {
          ...msalConfig.auth,
          clientId: azureAD.clientId,
          authority: `https://login.microsoftonline.com/${azureAD.tenantId}`,
        },
      };

      msalInstance = new PublicClientApplication(msalConfigFilled);
      msalInstance.initialize().then();

      // Default to using the first account if no account is active on page load
      if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
        // Account selection logic is app dependent. Adjust as needed for different use cases.
        msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
      }

      // Optional - This will update account state if a user signs in from another tab or window
      msalInstance.enableAccountStorageEvents();

      msalInstance.addEventCallback(event => {
        if (event.eventType === EventType.LOGIN_SUCCESS && (event.payload as AuthenticationResult).account) {
          const account = (event.payload as AuthenticationResult).account;
          msalInstance.setActiveAccount(account);
        }

        if (event.eventType === EventType.LOGOUT_SUCCESS) {
          if (msalInstance.getAllAccounts().length > 0) {
            msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
          }
        }
      });
    }

    return msalInstance;

  }, [azureAD.clientId, azureAD.tenantId]);
};
