import { createAsyncThunk } from '@reduxjs/toolkit';
import { endpoints } from 'config/endpoints';
import {
  DeleteCompany,
  DeleteCompanyResponse,
  DeleteUser,
  DeleteUserResponse,
  GetCompanies,
  GetCompaniesResponse,
  GetUsers,
  GetUsersResponse,
  PatchCompany,
  PatchCompanyResponse,
  PatchUpdateCompanyRequest,
  PatchUpdateUserRequest,
  PatchUser,
  PatchUserResponse,
  PostAddCompanyRequest,
  PostAddUserRequest,
  PostCompany,
  PostCompanyResponse,
  PostUser,
  PostUserResponse,
  types,
} from './types';
import { AsyncThunkParams } from '../app/types';
import { BackendResponseService } from '../../services/BackendResponseService';

export const fetchUsers = createAsyncThunk<
GetUsers | null,
AsyncThunkParams<GetUsersResponse>
>(
  types.USERS_FETCH,
  async (
    props,
  ) => {
    try {
      const result = await props.execute('GET', endpoints.cmsUsersGetUsers);
      return BackendResponseService.getBackendResponseData(result);
    } catch (err) {
      console.error(types.USERS_FETCH, err);
      return null;
    }
  },
);

export const postUser = createAsyncThunk<
PostUser | null,
AsyncThunkParams<PostUserResponse, PostAddUserRequest>
>(
  types.USER_POST,
  async (
    { execute, payload },
  ) => {
    try {
      if (payload) {
        const result = await execute(
          'POST',
          `${endpoints.cmsUsersPostUser}`,
          payload,
        );
        return BackendResponseService.getBackendResponseData(result);
      } else {
        return null;
      }
    } catch (err) {
      console.error(types.USER_POST, err);
      throw err;
    }
  },
);

export const patchUser = createAsyncThunk<
PatchUser | null,
AsyncThunkParams<PatchUserResponse, PatchUpdateUserRequest>
>(
  types.USER_PATCH,
  async (
    { execute, payload },
  ) => {
    try {
      if (payload) {
        const result = await execute(
          'PATCH',
          endpoints.cmsUsersPatchUser.replace(':userId', payload.userId.toString()),
          payload,
        );
        return BackendResponseService.getBackendResponseData(result);
      } else {
        return null;
      }
    } catch (err) {
      console.error(types.USER_PATCH, err);
      throw err;
    }
  },
);

export const deleteUser = createAsyncThunk<
DeleteUser | null,
AsyncThunkParams<DeleteUserResponse, { userId: number }>
>(
  types.USER_DELETE,
  async (
    { execute, payload },
  ) => {
    try {
      const result = await execute(
        'DELETE',
        endpoints.cmsUsersDeleteUser.replace(':userId', payload?.userId.toString() as string),
      );
      return BackendResponseService.getBackendResponseData(result);
    } catch (err) {
      console.error(types.USER_DELETE, err);
      throw err;
    }
  },
);

export const fetchCompanies = createAsyncThunk<
GetCompanies | null,
AsyncThunkParams<GetCompaniesResponse>
>(
  types.COMPANIES_FETCH,
  async (
    props,
  ) => {
    try {
      const result = await props.execute('GET', endpoints.cmsUsersGetCompanies);

      return BackendResponseService.getBackendResponseData(result);
    } catch (err) {
      console.error(types.COMPANIES_FETCH, err);
      return null;
    }
  },
);

export const postAddCompany = createAsyncThunk<
PostCompany | null,
AsyncThunkParams<PostCompanyResponse, PostAddCompanyRequest>
>(
  types.COMPANY_POST,
  async (
    { execute, payload },
  ) => {
    try {
      const result = await execute('POST', endpoints.cmsUsersPostCompany, payload);
      return BackendResponseService.getBackendResponseData(result);
    } catch (err) {
      console.error(types.COMPANY_POST, err);
      throw err;
    }
  },
);

export const patchCompany = createAsyncThunk<
PatchCompany | null,
AsyncThunkParams<PatchCompanyResponse, Partial<PatchUpdateCompanyRequest>>
>(
  types.COMPANY_PATCH,
  async (
    { execute, payload },
  ) => {
    try {
      if (payload) {
        const result = await execute(
          'PATCH',
          endpoints.cmsUsersPatchCompany.replace(':companyId', payload.companyId?.toString() as string),
          { companyType: payload.companyType },
        );
        return BackendResponseService.getBackendResponseData(result);
      } else {
        return null;
      }
      
    } catch (err) {
      console.error(types.COMPANY_PATCH, err);
      throw err;
    }
  },
);

export const deleteCompany = createAsyncThunk<
DeleteCompany | null,
AsyncThunkParams<DeleteCompanyResponse, { companyId: number }>
>(
  types.COMPANY_DELETE,
  async (
    { execute, payload },
  ) => {
    try {
      const result = await execute(
        'DELETE',
        endpoints.cmsUsersDeleteCompany.replace(':companyId', payload?.companyId.toString() as string),
      );
      return BackendResponseService.getBackendResponseData(result);
    } catch (err) {
      console.error(types.COMPANY_DELETE, err);
      throw err;
    }
  },
);
