import { createAsyncThunk } from '@reduxjs/toolkit';
import { AsyncThunkParams } from '../app/types';
import { endpoints } from '../../config/endpoints';
import {
  GetExperience,
  GetExperienceGamesFields,
  GetExperienceGamesResponse,
  GetExperienceResponse,
  PutExperience,
  PutExperienceRequest,
  PutExperienceResponse,
  types,
} from './types';
import { BackendResponseService } from '../../services/BackendResponseService';

export const fetchExperience = createAsyncThunk<
GetExperience | null,
AsyncThunkParams<GetExperienceResponse, { siteId: string }>
>(
  types.EXPERIENCE_FETCH,
  async (
    { execute, payload },
  ) => {
    try {
      if (payload) {
        const result = await execute(
          'GET',
          endpoints.experience.replace(':siteId', payload.siteId),
        );
        return BackendResponseService.getBackendResponseData(result);
      } else {
        return null;
      }
    } catch (err) {
      console.error(types.EXPERIENCE_FETCH, err);
      return null;
    }
  },
);

export const putExperience = createAsyncThunk<
PutExperience | null,
AsyncThunkParams<PutExperienceResponse, PutExperienceRequest>
>(
  types.EXPERIENCE_PUT,
  async (
    { execute, payload },
  ) => {
    try {
      if (payload) {
        const result = await execute('PUT', endpoints.experiencePut, payload);
        return BackendResponseService.getBackendResponseData(result);
      } else {
        return null;
      }
    } catch (err) {
      console.error(types.EXPERIENCE_PUT, err);
      return null;
    }
  },
);

export const fetchExperienceGames = createAsyncThunk<
GetExperienceGamesFields | null,
AsyncThunkParams<GetExperienceGamesResponse, { siteId: string }>
>(
  types.EXPERIENCE_GAMES_FETCH,
  async (
    { execute, payload },
  ) => {
    try {
      if (payload) {
        const result = await execute(
          'GET',
          endpoints.experienceGames.replace(':siteId', payload.siteId),
        );
        return BackendResponseService.getBackendResponseData(result);
      } else {
        return null;
      }
    } catch (err) {
      console.error(types.EXPERIENCE_GAMES_FETCH, err);
      return null;
    }
  },
);
