export enum IconEnums {
  CLOSE = 'close',
  FLAG = 'flag',
  PLUS = 'plus',
  PENCIL = 'pencil',
  PENCIL_CIRCLE = 'pencil-circle',
  MOUNTAIN = 'mountain',
  CLOVER = 'clover',
  CLOVER_FILL = 'clover-fill',
  MAGNIFIER = 'magnifier',
  RENAME = 'rename',
  REPLACE = 'replace',
  TRASH = 'trash',
  EXCLAMATION_MARK = 'exclamation-mark',
  SMALL_IMAGE = 'small-image',
  USERS = 'users',
  ROLES = 'roles',
  ARROW1 = 'arrow1',
  HELP = 'help',
  WHATS_NEW = 'magic',
  GIVE_A_FEEDBACK = 'nice',
  LOGOUT = 'logout',
  DUPLICATE = 'duplicate',
  NETWORK = 'network',
  BRANDS = 'brands',
  SETTINGS = 'settings',
  SETTINGS_TRANSPARENT = 'settings-transparent',
  SEO = 'seo',
  THEME = 'theme',
  LIKE = 'like',
  CHART = 'chart',
  CALENDAR = 'calendar',
  ATTENTION = 'attention',
  RADIOWAVES = 'radiowaves',
  PAUSE = 'pause',
  PAUSE_CIRCLE = 'pause-circle',
  IMAGES_GROUP = 'images-group',
  BUTTONS = 'buttons',
  COLOR_PALETTE = 'color-palette',
  ICONS = 'icons',
  IMAGES = 'images',
  GHOST = 'ghost',
  T1 = 't1',
  API = 'api',
  MODULAR = 'modular',
  SANDBOX = 'sandbox',
  ATOM = 'atom',
  CLOCK = 'clock',
  PICKER_BACKGROUND = 'picker-background',
  PICKER_STROKE = 'picker-stroke',
  PICKER_TEXT = 'picker-text',
  PICKER_ICON = 'picker-icon',
  SUN_MAX_FILL = 'sun-max-fill',
  MOON_FILL = 'moon-fill',
  MOUNTAIN_FRAME = 'mountain-frame',
  MOUNTAIN_FRAME_FILL = 'mountain-frame-fill',
  BROWSE_VIEW = 'browse-view',
  LEFT_TOP_NAV_DESKTOP_MENU = 'left-top-nav-desktop-menu',
  LEFT_SMALL_TOP_NAV_DESKTOP_MENU = 'left-small-top-nav-desktop-menu',
  MIDDLE_TOP_NAV_MOBILE_MENU = 'middle-top-nav-mobile-menu',
  LIST = 'list',
  EYE = 'eye',
  SUBCATEGORIES = 'subcategories',
  LIVE_CASINO = 'live-casino',
  JACKPOTS = 'jackpots',
  SPORT = 'sport',
  LINK = 'link',
  BINGO = 'bingo',
  FOR_YOU = 'for-you',
  PROMOTIONS = 'promotions',
  CHAT = 'chat',
  LOYALTY = 'loyalty',
  FEATURED_GAMES = 'featured-games',
  SLOTS = 'slots',
  TABLE_GAMES = 'table-games',
  WIZARD = 'wizard',
  DESKTOP = 'desktop',
  MOBILE = 'mobile',
  TABLET = 'tablet',
  APPLE = 'apple',
  ANDROID = 'android',
  MARK = 'mark',
  NO_INTERNET = 'no-internet',
  NOTIFICATION_ATTENTION = 'notification-attention',
  API_MINIMIZE = 'api-minimize',
  T1_MINIMIZE = 't1-minimize',
  MODULAR_MINIMIZE = 'modular-minimize',
  ATOM_MINIMIZE = 'atom-minimize',
  TRANSFER = 'transfer',
  WITHOUT_ICON = '',
  SEARCH = 'search',
  FILTER = 'filter',
  SORT = 'sort',
  VIEW = 'view',
  LOCK = 'lock',
  ARCHIVED = 'archived',
  SELECT = 'select',
  THREE_DOTS = 'three-dots',
  EDIT = 'edit',
  EDIT_RC = 'edit-rc',
  RESTORE = 'restore',
  CASINO = 'casino',
  REMOVE_LINE = 'remove-line',
  ARROW_TRIANGLE = 'arrow-triangle',
  ARCHIVED_DARK = 'archived-dark',
  BALANCE = 'balance',
  BRANDS_LOGO = 'brands-logo',
  CONTACT_FORM = 'contact-form',
  DOC_UPLOADER = 'doc-uploader',
  HELP_FILL = 'help-fill',
  INFORMATION = 'information',
  MAIN_MENU = 'main-menu',
  MY_ACCOUNT = 'my-account',
  TOURNAMENTS = 'tournaments',
  PAGE = 'page',
  EDIT_MEDIUM = 'edit-medium',
  RESTORED = 'restored',
  CHECK_MARK = 'check-mark',
  ARROW_UP = 'arrow-up',
  BURGER_MENU = 'burger-menu',
  MINIGAMES = 'minigames',
  NETWORK_MEDIA = 'network-media',
  NAV_BRANDS = 'nav-brands',
  NAV_BRANDS_ACTIVE = 'nav-brands-active',
  PINNED = 'pinned',
  PIN = 'pin',
  HOME = 'home',
  ROLLBACK = 'rollback',
  DOWNLOAD = 'download',
  EXCLAMATION_ROUNDED = 'exclamation-rounded',
}

export enum BgShapeEnum {
  CIRCLE = 'circle',
  SQUARE = 'square',
}
