import {
  FC,
  PropsWithChildren,
} from 'react';
import './NotificationWrapper.scss';

export const NotificationWrapper: FC<PropsWithChildren> = ({ children }) => {
  return (
    <div className="notification-wrapper">
      {children}
    </div>
  );
};
