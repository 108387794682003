import {
  BackendResponse,
  TranslatableField,
  Company,
  UploadedFile,
  Language,
} from '../app/types';
import { EntitySaveOperationEnum } from 'store/app/enums/entity-save-operation.enum';
import { Brand } from '../brands/types';
import { PamInstanceUrlsEnum } from '../network-editor/types';
import { BrandAsset } from '../assets/types';
import { SegmentEnum } from '../../common/SegmentEnum';

export const types = Object.freeze({
  GAMES_FETCH: '[CasinoGames] GAMES_FETCH',
  GAME_FETCH: '[CasinoGames] GAME_FETCH',
  GAME_UPDATE: '[CasinoGames] GAME_UPDATE',
  NEW_GAMES_FETCH: '[CasinoGames] NEW_GAMES_FETCH',
  UPDATE_GAME_STATE: '[CasinoGames] UPDATE_GAME_STATE',
  GAME_SETTINGS_FETCH: '[CasinoGames] GAME_SETTINGS_FETCH',
  GAME_SETTINGS_UPDATE: '[CasinoGames] GAME_SETTINGS_UPDATE',
  GAMES_CATEGORIES_FETCH: '[CasinoGames] GAMES_CATEGORIES_FETCH',
  GAMES_CATEGORIES_POST: '[CasinoGames] GAMES_CATEGORIES_POST',
  GAMES_CATEGORIES_PUT: '[CasinoGames] GAMES_CATEGORIES_PUT',
  CATEGORY_GROUPS_FETCH: '[CasinoGames] CATEGORY_GROUPS_FETCH',
  CATEGORY_GROUP_FETCH: '[CasinoGames] CATEGORY_GROUP_FETCH',
  CATEGORY_GROUP_POST: '[CasinoGames] CATEGORY_GROUP_POST',
  CATEGORY_GROUP_PUT: '[CasinoGames] CATEGORY_GROUP_PUT',
  CATEGORY_GROUP_DELETE: '[CasinoGames] CATEGORY_GROUP_DELETE',
});

export type CasinoGamesState = {
  filterState: FilterSettings | null;
  gamesProperties: GetGames | null;
  gameEditor: CompanyGameForEditor | null;
  pamInstancesCollection: { [key: number]: PamInstance } | null;
  gameSettings: GameSettings[] | null;
  brandsRegulationsCountries: BrandsRegulationsCountries | null;
  newGamesProperties: GetNewGames | null;
  gamesCategoriesProperties: GetGamesCategories | null;
  categoryGroups: GetCategoryGroups | null;
  currentCategoryGroups: GetCategoryGroup | null;
};

export type GetGamesRequest = {
  isNetwork: boolean;
  companyId: number | null;
  siteId?: number;
  statusIds?: number[];
  platformIds?: number[];
  gameLockIds?: number[];
  searchInput?: string;
  orderBy?: string;
  orderDirection?: string;
};

export type GetGamesResponse = BackendResponse<GetGames>;
export type GetNewGamesResponse = BackendResponse<GetNewGames>;
export type GetGamesCategoriesResponse = BackendResponse<GetGamesCategories>;
export type PostGameCategoryResponse = BackendResponse<PostGameCategory>;
export type PutGameCategoryResponse = BackendResponse<PutGameCategory>;
export type PutGameSettingsResponse = BackendResponse<void>;

export type PostGameCategory = {
  createdGameCategoryId: number;
};

export type PutGameCategory = {
  updatedGameCategoryId: number;
};

export enum GameCategoryStatusEnum {
  DRAFT = 1,
  PUBLISHED = 2,
  CHANGED = 3,
  ARCHIVED  = 4,
  FROZEN = 5,
}

export enum GameCategoryContentTypeEnum {
  GAME = 1,
  DYNAMIC_BLOCK = 2,
}

export enum GameCategorySettingsEnum {
  PLATFORMS = 'platforms',
  REGULATIONS = 'regulations',
  COUNTRIES = 'countries',
  BRANDS = 'brands',
  EXPIRY = 'expiryDate',
}

// Games tab types
export type GetGames = {
  filterSettings: FilterSettings;
  games: CompanyGame[];
  gamesArchived: CompanyGame[];
  hasNewGames: boolean;
  viewProperties: {
    companyInstances: Record<SegmentEnum, Brand[]>;
    companiesAvailable: Company[];
  };
};

export type CompanyGameImage = {
  assetId: number;
  thumbnailUrl: string | null;
  url: string;
};

export type NewGame = Pick<CompanyGame, 'pamGameId' | 'pamGameName' | 'image'>;

export type GetNewGamesRequest = {
  companyId: number;
  searchInput?: string;
};

export type GetNewGames = {
  newGames: NewGame[];
  notConfiguredGames: NewGame[];
};

export type FilterEntity = {
  name: string;
  value: number;
};

export type FilterSettings = {
  gameLocks: FilterEntity[];
  platforms: FilterEntity[];
  statuses: FilterEntity[];
};

export type CompanyGame = {
  companyGameId: number;
  pamGameId: number;
  pamGameStatus: number;
  statusId: number;
  image: CompanyGameImage | null;
  pamGameName: string;
  gameProviderName: string | null;
  gameSubProviderName: string | null;
  enabledPlatforms: {
    entityId: number;
    isEnabled: boolean;
    isLocked: boolean;
    comment: string | null;
  }[] | null;
  demoModeEnabled: {
    isEnabled: boolean;
    isLocked: boolean;
    comment: string | null;
  } | null;
  gameLocks: {
    loyaltyLevel: {
      enabled: boolean;
      level: number | null;
    };
    period: {
      enabled: boolean;
      fromDate: string | null;
      toDate: string | null;
    };
    deposit: {
      enabled: boolean;
      level: number | null;
    };
  } | null;
  createdDate: string;
  lastEditDate: string;
};

export type GetGameRequest = {
  companyGameId: number;
};

export type GetGameResponse = BackendResponse<CompanyGameForEditor>;

export type CompanyGameForEditor = {
  companyGameId: number;
  pamGameId: number;
  statusId: number;
  pamGameName: string;
  slug: string;
  gameProviderName: string | null;
  gameSubProviderName: string | null;
  createdDate: string;
  lastEditDate: string;
  fieldsByBrand: CompanyGameFieldsByBrand[];
};

export type CompanyGameFieldsByBrand = {
  siteId: number;
  brandName: string;
  gameFields: CompanyGameFieldWithAssets;
};

export type CompanyGameFieldWithAssets = {
  mainThumbnailImage: BrandAsset | null;
  verticalRectangleImage: BrandAsset | null;
  horizontalRectangleImage: BrandAsset | null;
};

export type UpdateGameRequest = {
  companyGameId: number;
  meta: UpdateGameMeta;
  fieldsByBrand?: UpdateGameFieldsByBrand[];
};

export type UpdateGameFieldsByBrand = {
  siteId: number;
  fields: UpdateGameFields;
};

export type UpdateGameFields = {
  mainThumbnailImage: number | null;
  verticalRectangleImage: number | null;
  horizontalRectangleImage: number | null;
};

export type UpdateGameMeta = {
  operationId: EntitySaveOperationEnum;
};

export type PatchGameStateRequest = {
  companyId: number;
  pamGameId: number;
  operationId: number;
};

export type GetGameSettingsResponse = BackendResponse<GetGameSettings>;

export type GetGameSettings = {
  settingsByBrand: GameSettings[];
  pamInstancesCollection: {
    [key: number]: PamInstance;
  };
  brandsRegulationsCountries: BrandsRegulationsCountries;
};

export type BrandsRegulationsCountries = {
  [key: number]: {
    countryIds: number[];
    regulationIds: number[];
    regulationsCountriesMap: {
      [key: number]: number[];
    };
  };
};

export type GameSettingsPlatform = {
  isEnabled: boolean;
  isLocked: boolean;
  comment: string | null;
  entityId: number;
};

export type DemoModeEnabled = {
  isEnabled: boolean;
  isLocked: boolean;
  comment: string | null;
};

export type SettingsRegulations = {
  isEnabled: boolean;
  isLocked: boolean;
  comment: string;
  entityId: number;
};

export type GameSettingsValues = {
  platforms: GameSettingsPlatform[];
  demoModeEnabled: DemoModeEnabled;
  countries: SettingsRegulations[];
  regulations: SettingsRegulations[];
};

export type GameSettings = {
  siteId: number | null;
  brandName: string | null;
  settings: {
    [key: number]: GameSettingsValues;
  };
};

export type PamInstance = {
  pamInstanceId: number;
  urls: {
    url: string;
    urlType: PamInstanceUrlsEnum;
  }[];
  pamInstanceDescription: string;
  regulations: number[];
};

export type SettingsByBrand = {
  pamInstanceId: number;
  demoModeEnabled: boolean;
};

export type UpdateGameSettings = {
  companyId: number;
  pamGameId: number;
  settingsByBrand: {
    siteId: number | null;
    settings: SettingsByBrand[];
  }[];
};

export type GamesCategorySettings = {
  categoryId: number;
  [GameCategorySettingsEnum.EXPIRY]: Date | null;
  [GameCategorySettingsEnum.PLATFORMS]: number[];
  [GameCategorySettingsEnum.REGULATIONS]: number[];
  [GameCategorySettingsEnum.COUNTRIES]: number[];
  [GameCategorySettingsEnum.BRANDS]: number[];
};
export enum GameCategoryFieldsEnum {
  GAME_CATEGORY_TITLE = 'gameCategoryTitle',
  GAME_CATEGORY_GAMES_POOL = 'gameCategoryGamesPool',
  GAME_CATEGORY_ICONS = 'gameCategoryIcons',
}

export type GameCategoryIcons = Record<string | 'network', number | null>;
export type GameCategoryIconsFiles = Record<string | 'network', UploadedFile | null>;
export enum GameCategoryGamesPoolEnum {
  CUSTOM = 1,
  PLAYER_FAVORITES = 2,
  OPTIMIZED_DYNAMICALLY = 3,
}

export type GamesCategoriesListItemTag = {
  tagId: number;
  tagName: string;
  tagStatus: number;
  tagLocked: boolean;
  tagAutoAdd: boolean;
  expiryDate: Date | null;
};

export type GamesCategoriesListItemContent = {
  contentId: number;
  contentEntityType: number;
  contentEntityId: number;
  contentOrder: number;
  contentPinned: boolean | null;
};

export type GamesCategoriesListItem = GamesCategorySettings & {
  companyId: number;
  statusId: number;
  [GameCategoryFieldsEnum.GAME_CATEGORY_TITLE]: TranslatableField | null;
  [GameCategoryFieldsEnum.GAME_CATEGORY_GAMES_POOL]: number;
  categoryGroups: IncludedCategoryGroup[];
  followTags: GamesCategoriesListItemTag[];
  [GameCategoryFieldsEnum.GAME_CATEGORY_ICONS]: GameCategoryIconsFiles;
  content: GamesCategoriesListItemContent[];
  excludedGamesIds: number[];
  liveGamesCount: number;
};

export type IncludedCategoryGroup = {
  groupId: number;
  groupName: string;
  groupOrder: number;
};

export type ListGamesCategoriesFilterSettingsItem = {
  value: number | string;
  name: string;
};

export type ListGamesCategoriesFilterSettings = {
  statuses: ListGamesCategoriesFilterSettingsItem[];
  countries: ListGamesCategoriesFilterSettingsItem[];
  userStates: ListGamesCategoriesFilterSettingsItem[];
  platforms: ListGamesCategoriesFilterSettingsItem[];
  regulations: ListGamesCategoriesFilterSettingsItem[];
  brands: ListGamesCategoriesFilterSettingsItem[];
};

export type GetGamesCategoriesRequest = {
  companyId: number | null;
  isNetwork: boolean;
};

export type GetGamesCategories = {
  gamesCategoriesCollection: Record<string, GamesCategoriesListItem>;
  listIds: number[];
  archivedIds: number[];
  filterSettings: ListGamesCategoriesFilterSettings;
  translatableFieldLanguages: Language[];
};

export type GameCategoryMeta = {
  companyId: number;
  operationId: EntitySaveOperationEnum;
};

export type UpdateGameCategoryMeta = Pick<GameCategoryMeta, 'operationId'>;

export type GameCategoryFields = {
  [GameCategoryFieldsEnum.GAME_CATEGORY_TITLE]: TranslatableField;
  [GameCategoryFieldsEnum.GAME_CATEGORY_GAMES_POOL]: number;
  [GameCategoryFieldsEnum.GAME_CATEGORY_ICONS]: GameCategoryIcons;
};

export type GameCategorySettings = {
  [GameCategorySettingsEnum.EXPIRY]: Date | null;
  [GameCategorySettingsEnum.PLATFORMS]: number[];
  [GameCategorySettingsEnum.REGULATIONS]: number[];
  [GameCategorySettingsEnum.COUNTRIES]: number[];
  [GameCategorySettingsEnum.BRANDS]: number[];
};

export type GameCategoryContent = {
  entityId: number;
  entityType: GameCategoryContentTypeEnum;
  order: number;
  pinned: boolean | null;
};

export type PostAddGameCategory = {
  meta: GameCategoryMeta;
  fields: GameCategoryFields;
  settings: GameCategorySettings;
  content: GameCategoryContent[];
};

export type PostAddGameCategoryRequest = PostAddGameCategory;

export type PutUpdateGameCategory = {
  categoryId: number;
  meta: UpdateGameCategoryMeta;
  fields: GameCategoryFields;
  settings: GameCategorySettings;
  content: GameCategoryContent[];
};

export type PutUpdateGameCategoryRequest = PutUpdateGameCategory;

export type PutArchiveGameCategoryRequest = Pick<PutUpdateGameCategory, 'categoryId' | 'meta'>;

export type GetCategoryGroupsResponse = BackendResponse<GetCategoryGroups>;

export type GetCategoryGroupResponse = BackendResponse<GetCategoryGroup>;

export type CategoryGroup = {
  categoryGroupId: number;
  categoryGroupName: string;
  companyId: number;
  categoriesInGroup: number[];
};

export type CompanyCategory = {
  categoryId: number;
  categoryName: string;
  icon: {
    fileId: number;
    thumbnailUrl: string | null;
    url: string;
  } | null;
};

export type GetCategoryGroups = {
  categoryGroups: CategoryGroup[];
  companyCategories: CompanyCategory[];
};

export type GetCategoryGroup = CategoryGroup & {
  categoriesInGroup: number[];
  companyCategories: {
    [key: number]: CompanyCategory;
  };
};

export type PutCategoryGroups = Pick<CategoryGroup, 'categoryGroupId' | 'categoryGroupName'> & {
  gameCategoryIds: number[];
};

export type CreateCategoryGroup = Pick<CategoryGroup, 'companyId' | 'categoryGroupName'> & {
  gameCategoryIds: number[];
};

export type CasinoGamesFilters = {
  selectedStatusIds?: number[];
  selectedPlatformIds?: number[];
  selectedGamesLockIds?: number[];
};
