export const DateService = {
  getDate(date: string): string {
    const data = new Date(date);

    return data.toLocaleDateString();
  },

  getTime(date: string, options?: Intl.DateTimeFormatOptions, locales = 'en-US'): string {
    const data = new Date(date);

    return data.toLocaleTimeString(locales, { ...options });
  },
};
