import {
  useCallback,
  useEffect,
  useState,
} from 'react';

export type FormLocalValidator = ReturnType<typeof useCreateFormLocalValidator>;

/**
 * Hook that handles tabs state depending on underlying forms validation.
 */
export const useCreateFormLocalValidator = () => {
  const [submitButtonIsLoading, setSubmitButtonIsLoading] = useState(false);
  const [submitButtonIsDisabled, setSubmitButtonIsDisabled] = useState(true);
  const [submitButtonIsSucceed, setSubmitButtonIsSucceed] = useState(false);
  const [checkBeforeSubmit, setCheckBeforeSubmit] = useState(false);

  // underlying form puts own submit handler to this state that will be called by update button in header
  const [handleSubmit, setHandleSubmit] = useState<() => Promise<void>>(() => {
    return async () => {
      return;
    };
  });

  const [onBeforeSubmit, setOnBeforeSubmit] = useState(() => {
    return () => {
      return Promise.resolve(true);
    };
  });

  const resetState = useCallback(() => {
    setSubmitButtonIsLoading(false);
    setSubmitButtonIsDisabled(true);
    setSubmitButtonIsSucceed(false);
    setHandleSubmit(() => {
      return async () => {
        return;
      };
    });
    setOnBeforeSubmit(() => {
      return () => {
        return Promise.resolve(true);
      };
    });
  }, []);

  // submitButtonIsSucceed will show checkmark inside button. It will be reset after 3 seconds
  useEffect(() => {
    if (submitButtonIsSucceed) {
      const timeOut = setTimeout(() => {
        setSubmitButtonIsSucceed(false);
        setSubmitButtonIsDisabled(true);
      }, 3_000);

      return () => {
        clearTimeout(timeOut);
      };
    }
  }, [submitButtonIsSucceed]);

  // reset state on component unmount
  useEffect(() => {
    return resetState;
  }, []);

  return {
    submitButtonIsLoading,
    setSubmitButtonIsLoading,
    submitButtonIsDisabled,
    setSubmitButtonIsDisabled,
    submitButtonIsSucceed,
    setSubmitButtonIsSucceed,
    handleSubmit,
    setHandleSubmit,
    onBeforeSubmit,
    setOnBeforeSubmit,
    checkBeforeSubmit,
    setCheckBeforeSubmit,
    resetState,
  };
};
