import {
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import {
  CMSUsersState,
  GetCompanies,
  GetUsers,
} from './types';
import {
  fetchCompanies,
  fetchUsers,
} from './actions';

const initialState: CMSUsersState = {
  usersProperties: null,
  companiesProperties: null,
};

export const cmsUsersSlice = createSlice({
  name: 'cmsUsers',
  initialState,
  reducers: {
    clearUsersState(state) {
      state.usersProperties = null;
    },
    clearCompaniesState(state) {
      state.companiesProperties = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUsers.fulfilled, (
      state: CMSUsersState,
      action: PayloadAction<GetUsers | null>,
    ) => {
      if (action.payload !== null) {
        state.usersProperties = action.payload;
      }
    });
    builder.addCase(fetchCompanies.fulfilled, (
      state: CMSUsersState,
      action: PayloadAction<GetCompanies | null>,
    ) => {
      if (action.payload !== null) {
        state.companiesProperties = action.payload;
      }
    });
  },
});
