import {
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import {
  AssetsState,
  GetBrandAssets,
  GetBrandIcons,
  GetLogosAssetsFields,
} from './types';
import {
  fetchBrandAssets,
  fetchBrandIcons,
  fetchLogosAssets,
} from './actions';

const initialState: AssetsState = {
  images: null,
  logos: null,
  icons: null,
  experienceSettings: null,
};

export const assetsSlice = createSlice({
  name: 'assets',
  initialState,
  reducers: {
    clearBrandAssetsState(state) {
      state.images = null;
    },
    clearBrandAssetsLogosState(state) {
      state.logos = null;
    },
    clearBrandAssetsIconsState(state) {
      state.icons = null;
      state.experienceSettings = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchBrandAssets.fulfilled, (
      state: AssetsState,
      action: PayloadAction<GetBrandAssets | null>,
    ) => {
      if (action.payload !== null) {
        state.images = action.payload.images;
      }
    });

    builder.addCase(fetchLogosAssets.fulfilled, (
      state: AssetsState,
      action: PayloadAction<GetLogosAssetsFields | null>,
    ) => {
      if (action.payload !== null) {
        state.logos = action.payload;
      }
    });

    builder.addCase(fetchBrandIcons.fulfilled, (
      state: AssetsState,
      action: PayloadAction<GetBrandIcons | null>,
    ) => {
      if (action.payload !== null) {
        state.icons = action.payload.icons;
        state.experienceSettings = action.payload.experienceSettings;
      }
    });
  },
});
