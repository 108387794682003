import {
  useAppActions,
  useAppDispatch,
} from 'store/hooks';
import { useEffect } from 'react';
import { ConfigFile } from 'interfaces/config-file.type';

export const useAppConfig = () => {
  const {
    fillApiUrl,
    fillBuild,
    handleConfigReady,
    fillAppEnv,
    fillAzureEnv,
    fillCmsInstances,
  } = useAppActions();
  const dispatch = useAppDispatch();


  useEffect(() => {
    // fetch config.json from /public folder
    (async () => {
      const response = await fetch('/config.json');
      const data = await response.json() as ConfigFile;

      dispatch(fillApiUrl(data.apiUrl));
      dispatch(fillBuild(data.build ?? ''));
      dispatch(fillAppEnv(data.appEnv));
      dispatch(fillCmsInstances(data.cmsInstances));
      dispatch(fillAzureEnv(data.azureAD));
      dispatch(handleConfigReady(true));
    })();
  }, []);
};
