import {
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { NetworkMediaState } from './types';
import {
  fetchNetworkIcons,
  fetchNetworkImages,
} from './actions';
import {
  GetBrandAssets,
  GetBrandIcons,
} from '../assets/types';

const initialState: NetworkMediaState = {
  networkIcons: null,
  networkImages: null,
};

export const networkMediaSlice = createSlice({
  name: 'networkMedia',
  initialState,
  reducers: {
    clearNetworkImagesState(state) {
      state.networkImages = null;
    },
    clearNetworkIconsState(state) {
      state.networkIcons = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchNetworkImages.fulfilled, (
      state: NetworkMediaState,
      action: PayloadAction<GetBrandAssets | null>,
    ) => {
      if (action.payload !== null) {
        state.networkImages = action.payload.images;
      }
    });
    
    builder.addCase(fetchNetworkIcons.fulfilled, (
      state: NetworkMediaState,
      action: PayloadAction<GetBrandIcons | null>,
    ) => {
      if (action.payload !== null) {
        state.networkIcons = action.payload.icons;
      }
    });
  },
});
