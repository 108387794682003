export const JwtService = {
  parseJwt: (token: string) => {
    try {
      return JSON.parse(atob(token.split('.')[1]));
    } catch (e) {
      return null;
    }
  },
  isTokenExpired: (token: string) => {
    const jwt = JwtService.parseJwt(token);
    if (!jwt || !jwt.exp) {
      return true;
    }

    return Date.now() >= jwt.exp * 1000;
  },
};
