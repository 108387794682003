export enum NotificationEnum {
  SUCCESS = 'success',
  INFO = 'info',
  ERROR = 'error',
}

export const NotificationMessages = Object.freeze({
  CHANGES_SUCCESS: { message: 'Changes applied and published', type: NotificationEnum.SUCCESS },
  CHANGES_ERROR: { message: 'Changes have not been applied', type: NotificationEnum.ERROR },
});
