import { MenuItemProps } from '../components/shared/MenuItem/MenuItem';
import { useAppSelector } from '../store/hooks';

export const useChangeInstances = () => {
  const { cmsInstances } = useAppSelector(state => state.app);

  const currentPathname = window.location.origin;

  const currentInstance = cmsInstances?.find(instance => instance.url === currentPathname)?.icon || 'eu';

  const instanceMenuItems: MenuItemProps[] = cmsInstances.map(({ name, url, icon }) => ({
    text: name,
    colorIcon: icon,
    onClick: () => window.location.replace(url),
  }));

  return {
    currentInstance,
    instanceMenuItems,
  };
};
