import {
  BackendResponse,
  Country,
  LanguageWithIcon,
} from '../app/types';
import {
  Experience,
  PutExperienceRequest,
} from '../experience/types';

export const types = Object.freeze({
  LOCALIZATION_PROPS_FETCH: '[NetworkEditor] LOCALIZATION_PROPS_FETCH',
  LOCALIZATION_PROPS_PATCH: '[NetworkEditor] LOCALIZATION_PROPS_PATCH',
  CREDENTIALS_PAM_INSTANCES_FETCH: '[NetworkEditor] CREDENTIALS_PAM_INSTANCES_FETCH',
  CREDENTIALS_PAM_INSTANCES_PUT: '[NetworkEditor] CREDENTIALS_PAM_INSTANCES_PUT',
  NETWORK_EXPERIENCE_FETCH: '[NetworkEditor] NETWORK_EXPERIENCE_FETCH',
  NETWORK_EXPERIENCE_PUT: '[NetworkEditor] NETWORK_EXPERIENCE_PUT',
});

export type NetworkEditorState = {
  localizationProperties: GetLocalizationPropertiesFields | null;
  pamInstancesProperties: GetPamInstancesFields | null;
  networkExperienceProperties: GetNetworkExperienceFields | null;
};

export type GetLocalizationPropertiesResponse = BackendResponse<GetLocalizationPropertiesFields>;
export type PatchLocalizationPropertiesResponse = BackendResponse<void>;
export type GetPamInstancesResponse = BackendResponse<GetPamInstancesFields>;
export type PutPamInstancesResponse = BackendResponse<void>;
export type GetNetworkExperienceResponse = BackendResponse<GetNetworkExperienceFields>;
export type PutNetworkExperienceResponse = BackendResponse<UpdatedExperienceItems>;

export type GetLocalizationPropertiesFields = {
  languages: LanguageWithIcon[];
  currencies: Currency[];
  currenciesDictionary: CurrenciesDictionary[];
  countries: Country[];
  countriesDictionary: CountriesDictionary[];
  brandsLocalizationProperties: BrandsLocalizationProperty[];
  regulationsDictionary: RegulationDictionary[];
};

export type BrandsLocalizationProperty = {
  siteId: number;
  brandName: string;
  regulations: BrandsLocalizationPropertyRegulation[];
  languages: LanguageWithIcon[];
};

export type BrandsLocalizationPropertyRegulation = {
  regulationId: number;
  regulationCode: string;
};

export type CountriesDictionary = {
  dictionaryCountryId: number;
  countryCode: string;
  countryName: string;
};

export type Currency = {
  currencyId: number;
  currencyCode: string;
  currencySign: string;
  regulations: CurrencyRegulation[];
};

export type CurrencyRegulation = {
  regulationId: number;
  regulationCode: string;
  regulationName: string;
};

export type CurrenciesDictionary = {
  currencyCode: string;
  currencySign: string;
  dictionaryCurrencyId: number;
};

export type RegulationDictionary = {
  regulationId: number;
  regulationCode: string;
  regulationName: string;
};

export type PatchLocalizationPropertiesRequest = {
  languages: PatchLanguage[];
  currencies: PatchCurrency[];
  countries: PatchCountry[];
  deleteLanguages: number[];
  deleteCurrencies: number[];
  deleteCountries: number[];
};

export type PatchLanguage = {
  languageId?: LanguageWithIcon['languageId'];
  iconFileId: number | null;
} & Pick<LanguageWithIcon, 'languageCode' | 'languageName' | 'fallbackLanguageId'>;

export type PatchCurrency = {
  currencyId?: Currency['currencyId'];
  fallbackRegulations: number[];
} & Pick<Currency, 'currencyCode'>;

export type PatchCountry = {
  countryId?: Country['countryId'];
} & Pick<Country, 'countryCode' | 'regionCode' | 'languageId' | 'currencyId'>;

/**
 * Pam Instances
 */

export type GetPamInstancesFields = {
  pamInstances: PamInstance[];
  regulationsDictionary: RegulationDictionary[];
};

export enum PamInstanceUrlsEnum {
  CMS_ADAPTER = 1,
  WINNER_TICKER = 2,
  TOP_PLAYED_GAMES = 3,
  FAVOURITE_GAMES = 4,
  EXTERNAL_LOYALTY = 5,
}

export type PamInstanceUrl = {
  url: string;
  urlType: PamInstanceUrlsEnum;
};

export type PamInstance = {
  pamInstanceId: number;
  urls: PamInstanceUrl[];
  pamInstanceDescription: string;
  regulations: number[];
};

export type PutPamInstance = {
  pamInstanceId?: PamInstance['pamInstanceId'];
  urls: PamInstanceUrl[];
  pamInstanceDescription: string;
  regulations: number[];
};

export type PutPamInstancesRequest = {
  pamInstances: PutPamInstance[];
  deletePamInstancesIds: number[];
};

/**
 * Network experience
 */

export type GetNetworkExperienceFields = {
  experience: Experience[];
  gamesCategories: ExperienceGameCategory[];
};

export type ExperienceGameCategory = {
  id: number;
  title: string | null;
  thumbnailUrl: string | null;
  url: string | null;
};

export type UpdatedExperienceItems = {
  updatedExperienceItems: boolean;
};

export type PutNetworkExperienceRequest = PutExperienceRequest;
