import { useEffect } from 'react';
import { useCmsLanguages } from '../components/modules/Languages/hooks/use-cms-languages';
import { useCmsRegulations } from '../components/modules/Regulations/hooks/use-cms-regulations';
import { useCmsCountries } from '../components/modules/Countries/hooks/use-cms-countries';
import { useCmsActiveUser } from './use-cms-active-user';
import { useMsal } from '@azure/msal-react';
import { useCmsPermissionsMap } from './use-cms-permissions-map';
import { useFetchWithMsal } from './use-fetch-with-msal';
import { useMenusGuards } from '../components/pages/Menus/hooks/use-menus-guards';

export const useAppCommonEntities = () => {
  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();
  const { doGetActiveUserRequest } = useCmsActiveUser(activeAccount?.localAccountId as string);
  const { doGetPermissionsMapRequest } = useCmsPermissionsMap();
  const { doGetMenusGuardsRequest } = useMenusGuards();
  const { doGetLanguagesRequest } = useCmsLanguages();
  const { doGetRegulationsRequest } = useCmsRegulations();
  const { doGetCountriesRequest } = useCmsCountries();

  const { execute } = useFetchWithMsal();

  useEffect(() => {
    doGetActiveUserRequest();
    doGetPermissionsMapRequest();
    doGetMenusGuardsRequest();
    doGetLanguagesRequest();
    doGetRegulationsRequest();
    doGetCountriesRequest();
  }, [execute]);
};
