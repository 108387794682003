import { FC } from 'react';
import './InstancePicker.scss';
import { useToggle } from 'hooks/use-toggle';
import { MenuItemProps } from '../MenuItem/MenuItem';
import { Menu } from '../Menu/Menu';
import classNames from 'classnames';

interface Props {
  menuItems: MenuItemProps[];
  icon: string;
  text: string;
  isMinimize?: boolean;
  isHovered?: boolean;
}

export const InstancePicker: FC<Props> = ({
  menuItems,
  icon,
  text,
  isHovered,
  isMinimize,
}) => {
  const [isMenu, toggleMenu] = useToggle(false);

  const instanceClassName = classNames({
    'instance-picker__instance': true,
    'instance-picker__instance--minimize': isMinimize,
    'instance-picker__instance--minimize--hovered': isHovered,
  });

  const menuClassName = classNames({
    'instance-picker__menu': true,
    'instance-picker__menu--minimize': isMinimize,
    'instance-picker__menu--minimize--hovered': isHovered && isMenu,
  });

  return (
    <div
      className="instance-picker"
      title="Change CMS instance"
    >
      <div
        className={instanceClassName}
        onClick={toggleMenu}
        role="presentation"
      >
        <div className="instance-picker__instance__icon" style={{ 'background': `var(--${icon})` }}></div>
        <div className="instance-picker__instance__text">{text}</div>

      </div>

      {isMenu && <Menu menuList={menuItems} toggleMenu={toggleMenu} className={menuClassName} />}
    </div>
  );
};
