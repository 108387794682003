import {
  BackendResponse,
  CompanyTypeEnum,
} from '../app/types';

export const types = Object.freeze({
  EXPERIENCE_FETCH: '[Experience] EXPERIENCE_FETCH',
  EXPERIENCE_PUT: '[Experience] EXPERIENCE_PUT',
  EXPERIENCE_GAMES_FETCH: '[Experience] EXPERIENCE_GAMES_FETCH',
});

export type ExperienceState = {
  experienceData: Experience[] | null;
  experienceGamesProperties: GetExperienceGamesFields | null;
};

export type GetExperienceResponse = BackendResponse<GetExperience>;
export type PutExperienceResponse = BackendResponse<PutExperience>;
export type GetExperienceGamesResponse = BackendResponse<GetExperienceGamesFields>;

export type Experience = {
  brandExperienceId?: number;
  brandExperienceName: string;
  siteId: number | null;
  systemGroupId: number;
  translationGroupId?: number | null;
  brandExperienceValue: number;
};

export type PutExperienceItem = {
  brandExperienceId?: Experience['brandExperienceId'];
  brandExperienceName: string;
  siteId: number | null;
  systemGroupId: number;
  translationGroupId?: number | null;
  brandExperienceValue: number;
};

export type PutExperienceRequest = {
  experienceItems: PutExperienceItem[];
};

export type GetExperience = Experience[];

export type PutExperience = {
  updatedExperienceItems: boolean;
};

/**
 * Games experience
 */

export type GetExperienceGamesFields = {
  experience: Experience[];
  gamesCategories: ExperienceGameCategory[];
};

export type ExperienceGameCategory = {
  id: number;
  title: string | null;
  thumbnailUrl: string | null;
  url: string | null;
  companyType: CompanyTypeEnum;
};
