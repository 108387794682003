import {
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import {
  GetLicensesFields,
  GetRegulatedFeaturesFields,
  RegulationsState,
} from './types';
import {
  fetchLicenses,
  fetchRegulatedFeatures,
} from './actions';

const initialState: RegulationsState = {
  regulatedFeatures: null,
  licenses: null,
};

export const regulationsSlice = createSlice({
  name: 'regulations',
  initialState,
  reducers: {
    clearRegulatedFeaturesState(state) {
      state.regulatedFeatures = null;
    },
    clearLicensesState(state) {
      state.licenses = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchRegulatedFeatures.fulfilled, (
      state: RegulationsState,
      action: PayloadAction<GetRegulatedFeaturesFields | null>,
    ) => {
      if (action.payload !== null) {
        state.regulatedFeatures = action.payload;
      }
    });
    builder.addCase(fetchLicenses.fulfilled, (
      state: RegulationsState,
      action: PayloadAction<GetLicensesFields | null>,
    ) => {
      if (action.payload !== null) {
        state.licenses = action.payload;
      }
    });
  },
});
