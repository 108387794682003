import {
  FC,
  MouseEventHandler,
} from 'react';
import './SystemNotification.scss';
import { SystemCta } from '../SystemCta/SystemCta';

export interface SystemNotificationProps {
  title: string;
  subtitle: string;
  confirmButtonText: string;
  cancelButtonText?: string;
  onConfirm: MouseEventHandler;
  onCancel?: MouseEventHandler;
}

export const SystemNotification: FC<SystemNotificationProps> = ({
  title,
  subtitle,
  confirmButtonText,
  cancelButtonText,
  onConfirm,
  onCancel,
}) => {
  return (
    <div className="system-notification">
      <div className="system-notification__title">{title}</div>
      <div className="system-notification__subtitle">{subtitle}</div>

      <div className="system-notification__actions">
        <SystemCta text={confirmButtonText} onClick={onConfirm} />
        {cancelButtonText && <SystemCta text={cancelButtonText} onClick={onCancel} className="cancel-button" />}
      </div>
    </div>
  );
};
