import { useAppSelector } from '../../store/hooks';
import { useParams } from 'react-router-dom';
import { CompanyTypeEnum } from '../../store/app/types';

export const useCurrentPermissionsBrand = () => {
  const { siteId = '' } = useParams();
  const { user } = useAppSelector(state => state.app);
  // Get brand only for customer users
  const result = siteId && user?.companyType === CompanyTypeEnum.CUSTOMER
    ? Number(siteId)
    : null;
  return {
    siteId: result,
  };
};
