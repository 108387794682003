import { useEffect } from 'react';
import { useMsalAuthentication } from '@azure/msal-react';
import { loginRequest } from 'config/auth';
import {
  InteractionRequiredAuthError,
  InteractionType,
} from '@azure/msal-browser';

export const useMsalAuth = () => {
  // https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/3250#issuecomment-803140249
  const { login, error } = useMsalAuthentication(InteractionType.Silent, { ...loginRequest });

  useEffect(() => {
    if (error instanceof InteractionRequiredAuthError) {
      login(InteractionType.Redirect, { ...loginRequest }).then();
    } else if (error) {
      console.error(error);
    }
  }, [error]);
};
